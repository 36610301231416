export const COLUMNS = [
    {
        title: 'Numri',
        field: 'id'
    },
    {
        title: 'Emri',
        field: 'firstnameAndLastname',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    },
    {
        title: 'Telefoni',
        field: 'phoneNumber'
    },
    {
        title: 'Adresa',
        field: 'address'
    },
    {
        title: 'Qyteti',
        field: 'city'
    },
    {
        title: 'Data e krijimit',
        field: 'createdAt'
    },
    {
        title: 'Agjenti',
        field: 'user.userName'
    }
];

export const COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'firstnameAndLastname',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    }
];

export const MEASURE_COLUMNS = [
    {
        title: 'Numri',
        field: 'clientId'
    },
    {
        title: 'Emri',
        field: 'clientName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'clientLastName'
    },
    {
        title: 'Projekti',
        field: 'project.label'
    },
    {
        title: 'Telefoni',
        field: 'phoneNumber'
    },
    {
        title: 'Adresa',
        field: 'address'
    },
    {
        title: 'Shteti',
        field: 'country'
    },
    {
        title: 'Qyteti',
        field: 'city'
    },
    {
        title: 'Status',
        field: 'status.label'
    },
    {
        title: 'Data e krijimit',
        field: 'firstUpdate'
    },
    {
        title: 'Agjenti',
        field: 'user.userName'
    }
];

export const MEASURE_COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'clientNameAndLastName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    }
];

export const OFFER_COLUMNS = [
    {
        title: 'Numri',
        field: 'clientId'
    },
    {
        title: 'Emri',
        field: 'clientName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'clientLastName'
    },
    {
        title: 'Projekti',
        field: 'project.label'
    },
    {
        title: 'Telefoni',
        field: 'phoneNumber'
    },
    {
        title: 'Adresa',
        field: 'address'
    },
    {
        title: 'Shteti',
        field: 'country'
    },
    {
        title: 'Qyteti',
        field: 'city'
    },
    {
        title: 'Status',
        field: 'offerStatus.label'
    },
    {
        title: 'Data e krijimit',
        field: 'firstUpdate'
    },
    {
        title: 'Agjenti',
        field: 'user.userName'
    }
];

export const OFFER_COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'clientNameAndLastName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    }
];


export const AGREEMENT_COLUMNS = [
    {
        title: 'Numri',
        field: 'clientId'
    },
    {
        title: 'Emri',
        field: 'clientName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'clientLastName'
    },
    {
        title: 'Projekti',
        field: 'project.label'
    },
    {
        title: 'Telefoni',
        field: 'phoneNumber'
    },
    {
        title: 'Adresa',
        field: 'address'
    },
    {
        title: 'Shteti',
        field: 'country'
    },
    {
        title: 'Qyteti',
        field: 'city'
    },
    {
        title: 'Status',
        field: 'agreementStatus.label'
    },
    {
        title: 'Data e liferimit',
        field: 'productDates.latestEndDate'
    },

    {
        title: 'Data e krijimit',
        field: 'firstUpdate'
    },
    {
        title: 'Agjenti',
        field: 'user.userName'
    }
];

export const AGREEMENT_COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'clientNameAndLastName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    }
];


export const MEASURE_ARCHIVE_RECORD_COLUMNS = [
    {
        title: 'Numri i projektit',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Emri i klientit',
        field: 'clientName'
    },
    {
        title: 'Lokacioni',
        field: 'productLocation'
    },
    {
        title: 'Data e matjes',
        field: 'measureDate'
    },
];

export const MEASURE_ARCHIVE_RECORD_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Emri i klientit',
        field: 'clientName'
    }
];


export const OFFER_ARCHIVE_RECORD_COLUMNS = [
    {
        title: 'Numri i projektit',
        field: 'id'
    },
    {
        title: 'Emri i klientit',
        field: 'clientNameAndLastName'
    },
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Data e ofertes',
        field: 'offerDate'
    },
    {
        title: 'Statusi i ofertes',
        field: 'offerStatus.label'
    },
    {
        title: 'Totali i ofertes',
        field: 'offerTotalCalculation'
    },
];

export const OFFER_ARCHIVE_RECORD_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Emri i klientit',
        field: 'clientName'
    }
];

export const AGREEMENT_ARCHIVE_RECORD_COLUMNS = [
    {
        title: 'Numri i projektit',
        field: 'id'
    },
    {
        title: 'Emri i klientit',
        field: 'clientNameAndLastName'
    },
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Statusi',
        field: 'agreementStatus.label'
    },
    {
        title: 'Totali i marrveshjes',
        field: 'total'
    },
    {
        title: 'Totali i mbetur',
        field: 'totalDebtLeft'
    },
];

export const AGREEMENT_ARCHIVE_RECORD_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Emri i klientit',
        field: 'clientName'
    }
];

export const ARCHIVED_PRODUCTS_COLUMNS = [
    {
        title: 'Emri i klientit',
        field: 'clientName'
    },
    {
        title: 'Emri i projektit',
        field: 'projectName'
    },
    {
        title: 'Produkti',
        field: 'productName'
    },
    {
        title: 'Fillimi i procesit',
        field: 'trackersStart'
    },
    {
        title: 'Mbarimi i procesit',
        field: 'trackersEnd'
    },
    {
        title: 'Koha',
        field: 'duration'
    },
    {
        title: 'Sasia',
        field: 'quantity'
    }
];

export const ARCHIVED_RESPONSIBILITIES_COLUMNS = [
    {
        title: 'Klienti',
        field: 'client.clientName'
    },
    {
        title: 'Projekti',
        field: 'project.projectName'
    },
    {
        title: 'Produkti',
        field: 'product.articleName'
    },
    {
        title: 'Materiali',
        field: 'material.materialName'
    },
    {
        title: 'Procesi',
        field: 'responsibility.responsibilityName'
    },
    {
        title: 'Koha',
        field: 'totalDuration'
    },
    {
        title: 'Perdoruesi',
        field: 'user.userName'
    },
    {
        title: 'Reparti',
        field: 'departmentName'
    },
    {
        title: 'Data e fillimit',
        field: 'trackersStart'
    },
    {
        title: 'Data e perfundimit',
        field: 'completionDate'
    }
];

export const RETURNED_RESPONSIBILITIES_COLUMNS = [
    {
        title: 'Klienti',
        field: 'client.clientName'
    },
    {
        title: 'Projekti',
        field: 'project.projectName'
    },
    {
        title: 'Produkti',
        field: 'product.articleName'
    },
    {
        title: 'Materiali',
        field: 'material.materialName'
    },
    {
        title: 'Procesi',
        field: 'responsibility.responsibilityName'
    },
    {
        title: 'Perdoruesi',
        field: 'user.userName'
    },
    {
        title: 'Arsyeja e kthimit',
        field: 'adminWarning'
    }
];

export const WAREHOUSE_ARCHIVE_RECORD_COLUMNS = [
    {
        title: 'Numri i deponimit',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'project[0].projectName'
    }
];

export const TRANSPORT_RECORDS_COLUMNS = [
    {
        title: 'Numri i projektit',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'projectName'
    },
    {
        title: 'Data e nisjes se transportit',
        field: 'warehouseRecords[0].transport[0].orderAcceptanceDate'
    },
    {
        title: 'Data e pranimit te transportit',
        field: 'warehouseRecords[0].transport[0].orderArrivalDate'
    }
];

export const TRANSPORT_RECORDS_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Data e nisjes se transportit',
        field: 'orderAcceptanceDate'
    }
];


export const INSTALLATION_RECORDS_COLUMNS = [
    {
        title: 'Numri i projektit',
        field: 'project.value'
    },
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Komenti i projektit',
        field: 'project.projectComment'
    },
    {
        title: 'Lokacioni i projektit',
        field: 'project.projectLocation'
    }
];

export const INSTALLATION_RECORDS_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Lokacioni i projektit',
        field: 'project.projectLocation'
    }
];

export const INSTALLATION_RECORDS_COLUMNS_ARCHIVE = [
    {
        title: 'Numri i instalimit',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Komenti i projektit',
        field: 'project.projectComment'
    },
    {
        title: 'Lokacioni i projektit',
        field: 'project.projectLocation'
    }
];

export const INSTALLATION_RECORDS_COLUMNS_ARCHIVE_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.label'
    },
    {
        title: 'Lokacioni i projektit',
        field: 'project.projectLocation'
    }
];

export const SERVICE_RECORDS_COLUMNS_ARCHIVE = [
    {
        title: 'Numri i servisimit',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'project.projectName'
    },
    {
        title: 'Artikulli per servisim',
        field: 'articleForService'
    },
    {
        title: 'Problemi',
        field: 'problem'
    },
    {
        title: 'Verejtja',
        field: 'warning'
    }
];

export const SERVICE_RECORDS_COLUMNS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.projectName'
    },
    {
        title: 'Artikulli per servisim',
        field: 'articleForService'
    }
];


export const IMPRESSION_RECORDS = [
    {
        title: 'Numri i pershtypjes',
        field: 'id'
    },
    {
        title: 'Emri i projektit',
        field: 'project.projectName'
    },
    {
        title: 'Emri i klientit',
        field: 'project.client.firstnameAndLastname'
    },
    {
        title: 'Mbiemti i klientit',
        field: 'project.client.lastName'
    },
    {
        title: 'Komenti',
        field: 'feedback'
    },
    {
        title: 'Vleresimi',
        field: 'rating'
    }
];

export const IMPRESSION_RECORDS_MOBILE = [
    {
        title: 'Emri i projektit',
        field: 'project.projectName'
    },
    {
        title: 'Emri i klientit',
        field: 'project.client.firstnameAndLastname'
    }
];

export const ALL_MODULE_CONTENT_COLUMNS = [
    {
        title: 'Emri i projektit',
        field: 'projectName'
    }
];

export const  PRODUCTION_COLUMNS = [
    {
        title: 'Numri i klientit',
        field: 'id'
    },
    {
        title: 'Emri',
        field: 'firstnameAndLastname',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    },
    {
        title: 'Telefoni',
        field: 'phoneNumber'
    },
    {
        title: 'Adresa',
        field: 'address'
    },
    {
        title: 'Qyteti',
        field: 'city'
    },
    {
        title: "Data e fillimit",
        field: "product.earliestStartDate"
    },
    {
        title: "Data e mbarimit",
        field: `product.latestEndDate`
    }
]

export const  PRODUCTION_COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'firstnameAndLastname',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastName'
    }
]

export const  PASSIVE_PRODUCTS= [
    {
        title: 'Emri i klientit',
        field: 'clientName',
        customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
    },
    {
        title: 'Projekti',
        field: 'projectName'
    },
    {
        title: 'Produkti',
        field: 'productName'
    }
]

//
// export const AGREEMENT_COLUMNS = [
//     {
//         title: 'Emri',
//         field: 'firstnameAndLastname',
//         customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
//     },
//     {
//         title: 'Mbiemri',
//         field: 'lastName'
//     },
//     {
//         title: 'Telefoni',
//         field: 'phoneNumber'
//     },
//     {
//         title: 'Adresa',
//         field: 'address'
//     },
//     {
//         title: 'City',
//         field: 'city'
//     },
//     {
//         title: 'Data e liferimit',
//         field: 'deliveryDate'
//     },
// ];
//
// export const AGREEMENT_COLUMNS_MOBILE = [
//     {
//         title: 'Emri',
//         field: 'firstnameAndLastname',
//         customSort: (a, b) => a.firstnameAndLastname.length - b.firstnameAndLastname.length
//     },
//     {
//         title: 'Mbiemri',
//         field: 'lastName'
//     }
// ];

export const WAREHOUSE_COLUMNS = [
    {
        title: 'Emri dhe mbiemri',
        field: 'client',
        customSort: (a, b) => a.client.length - b.client.length
    },
    {
        title: 'Produkti',
        field: 'designation.label'
    },
    {
        title: 'Data e startimit te prodhimit',
        field: 'start'
    },
    {
        title: 'Data e kalkuluar e prodhimit',
        field: 'end'
    },
    {
        title: 'Data e furnizimit ne depo',
        field: 'end'
    }
];

export const ADMIN_COLUMNS = [
    {
        title: 'Emri',
        field: 'firstname',
        customSort: (a, b) => a.firstname.length - b.firstname.length
    },
    {
        title: 'Mbiemri',
        field: 'lastname'
    },
    {
        title: 'Emri i perdoruesit',
        field: 'username'
    },
    {
        title: 'Email',
        field: 'email'
    }
];


export const ADMIN_COLUMNS_MOBILE = [
    {
        title: 'Emri',
        field: 'firstname',
        customSort: (a, b) => a.firstname.length - b.firstname.length
    },
    {
        title: 'Emri i perdoruesit',
        field: 'username'
    }
];

export const RESPONSIBILITY_COLUMNS = [
    {
        title: 'Procesi prodhimit',
        field: 'label',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const SUPPLIER_COLUMNS = [
    {
        title: 'Procesi prodhimit',
        field: 'supplier',
        customSort: (a, b) => a.label.length - b.label.length
    }
]


export const COLOR_COLUMNS = [
    {
        title: 'Ngjyra',
        field: 'colorPalette',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const EQUIPMENT_COLUMNS = [
    {
        title: 'Emri i pajisjes',
        field: 'label',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const UNIT_COLUMNS = [
    {
        title: 'Njesia',
        field: 'name',
        customSort: (a, b) => a.name.length - b.name.length
    }
]

export const DEPARTMENT_COLUMNS = [
    {
        title: 'Reparti',
        field: 'label',
    }
]

export const ARTICLE_COLUMNS = [
    {
        title: 'Artikulli',
        field: 'label',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const WAREHOUSE_PRODUCTS_COLUMNS = [
    {
        title: 'Emri i artikullit',
        field: 'articles[0].label',
        customSort: (a, b) => a.label.length - b.label.length
    },
    {
        title: 'Sasia ne depo',
        field: 'quantity',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const MATERIAL_COLUMNS = [
    {
        title: 'Materiali',
        field: 'label',
        customSort: (a, b) => a.label.length - b.label.length
    }
]

export const OFFERS_FIELD_COLUMNS = [
    {
        title: 'Fusha',
        field: 'name',
    }
]

export const IMPRESSION_QUESTIONS_COLUMNS = [
    {
        title: 'Pyetja',
        field: 'label',
    }
]


export const AUDIT_LOG_COLUMNS = [
    {
        title: 'Username',
        field: 'audit.user.username'
    },
    {
        title: 'First Name',
        field: 'audit.user.firstname'
    },
    {
        title: 'Last Name',
        field: 'audit.user.lastname'
    },
    {
        title: 'Event',
        field: 'audit.event',
    }
]

export const WAREHOUSE_PRODUCTS = [
    {
        title: 'Numri',
        field: 'designation.value'
    },
    {
        title: 'Emri i klientit',
        field: 'clientName'
    },
    {
        title: 'Produkti',
        field: 'designation.label'
    },
    {
        title: 'Kodi i produktit',
        field: 'type'
    },
    {
        title: 'Reparti',
        field: 'department.label'
    },
    {
        title: 'Data e fillimit',
        field: 'start'
    },
    {
        title: 'Data e mbarimit',
        field: 'end'
    },
    {
        title: 'Data e deponimit',
        field: 'finishedProduct'
    },
    {
        title: 'Krijuesi i kontaktit',
        field: 'clientCreatedBy.label'
    }
]

export const WAREHOUSE_PRODUCTS_MOBILE = [
    {
        title: 'Produkti',
        field: 'designation.label'
    },
    {
        title: 'Data e deponimit',
        field: 'finishedProduct'
    }
]


export const TEMPLATE_COLUMNS = [
    {
        title: 'Template',
        field: 'nameOfFile'
    }
]

const exportCsv = (columns, renderData) => {
    // Customize the export behavior here
    let csvData = '';

    // Add custom headers to the CSV
    csvData += 'SHEHU L.L.C\n';
    columns?.forEach((column)=>{
        csvData += `${column?.title},`;
    })

    csvData += `\n`

    // Add each row of data
    renderData.forEach((rowData) => {
        columns?.forEach((column)=>{
            const cell = rowData[column?.field] ? rowData[column?.field] : '';
            csvData += `${cell},`;
        })
        csvData += `\n`;
    });

    const utf8BOM = '\uFEFF'; // UTF-8 BOM (Byte Order Mark) to indicate encoding

    // Trigger the file download
    const downloadLink = document.createElement('a');
    const blob = new Blob([utf8BOM, csvData], { type: 'text/csv;charset=utf-8;' });
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'TableData.csv';
    downloadLink.click();
};

export const OPTIONS = {
    pageSize: 50,
    pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100, 1000 ],
    exportButton: true,
    actionsColumnIndex: -1,
    sorting: true,
    exportCsv: exportCsv
};