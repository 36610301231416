import React, {useEffect, useState} from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Transport.scss'
import * as productionActions from '../../actions/production'
import TableBbros from '../../components/Table/TableBbros';
import {
    OPTIONS,
    TRANSPORT_RECORDS_COLUMNS,
    TRANSPORT_RECORDS_COLUMNS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import * as transportActions from "../../actions/transport";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {ClientObject} from "../../types/client.type";
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    transportActions: any
    transport: any
}

const AllTransportRecords = (props: Props) => {

    const [transportRecordId, setTransportRecordId] = useState<number>(null)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        props.transportActions.getProjectsForTransport()
    }, [])

    const editTransportRecord = (transportRecord) => {
        if(transportRecord.warehouseRecords[0]?.transport){
            props.history.push({pathname: `/dashboard/transport/create/${transportRecord.warehouseRecords[0]?.transport[0]?.code}`})
        }else{
            props.history.push({pathname: `/dashboard/transport/create`})
        }
    }

    const handleRemove = (transportRecord) => {
        setDeleteModal(true)
        setTransportRecordId(transportRecord?.id)
    }

    const deleteTransport = () => {
        props.transportActions.deleteTransportRecord(transportRecordId).then(()=>{
            props.transportActions.getAllTransportRecords()
        })
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Ndrysho',
            onClick: (event, rowData) => editTransportRecord(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData: ClientObject) => handleRemove(rowData)
        }
    ]

    return(
        <div className="wrapper warehouseContainer">
            <TableBbros
                title={translatedText({id: "table.warehouse", defaultMessage: "Transport"})}
                data={props?.transport?.projectsForTransport}
                columns={isMobile ? TRANSPORT_RECORDS_COLUMNS_MOBILE : TRANSPORT_RECORDS_COLUMNS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteTransport()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllTransportRecords as any)));