import * as constants from "../constants/constants";

export interface InstallationState {
    installationRecords: any
    installationRecordByProjectId: any
    installationRecordsForMap: any
    projectsForInstallation: any
}

const initialState = () => {
    return {
        installationRecords: [],
        installationRecordByProjectId: {},
        installationRecordsForMap: [],
        projectsForInstallation: []
    }
}

const admin = (state: InstallationState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_INSTALLATION_RECORDS: {
            return {
                ...state,
                installationRecords: action.payload
            };
        }
        case constants.FETCH_INSTALLATION_RECORD_BY_PROJECT_ID: {
            return {
                ...state,
                installationRecordByProjectId: action.payload
            };
        }
        case constants.FETCH_INSTALLATION_RECORDS_FOR_MAP: {
            return {
                ...state,
                installationRecordsForMap: action.payload
            };
        }
        case constants.FETCH_PROJECTS_FOR_INSTALLATION: {
            return {
                ...state,
                projectsForInstallation: action.payload
            };
        }
    }
    return state;
}

export default admin;