import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../actions/generalActions';
import * as measuresActions from '../../actions/measures';
import * as clientActions from '../../actions/client';
import {ParameterTypes} from '../../types/parameter.type';
import {SelectOption} from '../../types/selectOption.type';
import {RootState} from '../../reducers';
import {FormattedMessage, injectIntl} from 'react-intl';
import Spinner from '../../components/Spinner';
import * as warehouseActions from "../../actions/warehouse";
import './warehouse.scss'
import Select from "react-select";
import FontAwesome from 'react-fontawesome';
import Tooltip from "rc-tooltip";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import {editWarehouseRecords, getWarehouseLeftOvers} from "../../actions/warehouse";
import {isMobile} from "../../constants/constants";
import {EInputTextType, Input} from "../../components/Input/Input";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import clearFormSvg from "../../assets/icons/archeology.svg";
import WarehouseExtraInfo from "./WarehouseExtraInfo";
import {FileUpload} from "../../types/fileUpload.type";
import ReactToPrint from "react-to-print";
import FileIcon from "../../assets/icons/file-text.svg";
import {WarehouseRecordPrint} from "./WarehouseRecordPrint";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import WarehouseProjectCard from "./WarehouseProjectCard";

interface Props {
    history: any;
    intl: any;
    measuresActions: any;
    warehouse: any;
    warehouseActions: any;
    clientActions: any;
    loading: boolean;
    actions: any;
    archivedProducts: any;
    buttonLoader: boolean
    permissions: any
}

const CreateWarehouseForm = (props: Props) => {

    const {clientId} = useParams<ParameterTypes>();
    const [firstAndLastName, setFirstAndLastName] = useState<string>();
    const [projects, setProjects] = useState([])
    const [warehouseRecords, setWarehouseRecords] = useState([])
    const [projectData, setProjectData] = useState([])
    const [transportUsers, setTransportUsers] = useState([])
    const [installationUsers, setInstallationUsers] = useState([])
    const [transportAssignees, setTransportAssignees] = useState<any>([])
    const [installationAssignees, setInstallationAssignees] = useState<any>([])
    const [quantities, setQuantities] = useState([])
    const [expandedProjectIndex, setExpandedProjectIndex] = useState<number>(null)
    const [warehouseRecordFile, setWarehouseRecordFile] = useState<FileUpload[]>([] as FileUpload[])
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)

    useEffect(() => {
        props.actions.getArchivedProductsForWarehouse()
        props.warehouseActions.fetchTransportAssigneesOptions()
        props.warehouseActions.fetchInstallationAssigneesOptions()
    }, [])

    useEffect(() => {
        if (clientId) {
            props.clientActions.fetchClient(clientId, 2).then((res) => {
                setFirstAndLastName(`${res?.data?.firstnameAndLastname} ${res?.data?.lastName}`)
            })
            props.warehouseActions.fetchWarehouseClientInformation(clientId)
        }
    }, [clientId])

    useEffect(() => {
        setTransportUsers(props.warehouse?.transportAssigneesOptions)
    }, [props.warehouse?.transportAssigneesOptions])

    useEffect(() => {
        setInstallationUsers(props.warehouse?.installationAssigneesOptions)
    }, [props.warehouse?.installationAssigneesOptions])

    useEffect(() => {
        let tempArray = []
        props?.warehouse?.warehouseClientInformation?.offer?.forEach((offer) => {// let wantedProject = props?.warehouse?.warehouseClientInformation?.warehouseRecords?.find(record => record?.project[0]?.id === offer?.projectId)
            // if(wantedProject){
            tempArray.push({
                id: offer?.projectId,
                edit: true
            })
            // }else{
            //     tempArray.push({})
            // }
        })
        if (tempArray.length !== 0) {
            setProjectData(tempArray)
            setProjects(props?.warehouse?.warehouseClientInformation?.offer)
            setWarehouseRecords(props?.warehouse?.warehouseClientInformation?.warehouseRecords)
        }
    }, [props?.warehouse?.warehouseClientInformation])

    if (props.loading) return (<Spinner/>)
    return (
        <div className="wrapper warehouse-form-wrapper">
            {props?.warehouse?.warehouseClientInformation && <div className={'warehouseClientInformationContainer'}>
                <div className={'projectInformation'}>
                    <div className={'projectName d-flex w-100'}>
                        <div className={'w-100'}>
                            <p className={'titleField'}>Emri dhe mbiemri i klientit</p>
                            <p className={'m-0'}>{firstAndLastName}</p>
                        </div>
                    </div>
                </div>
                <div className={'projectContainer'}>
                    {projects?.map((project, index) => {
                        return (
                            <WarehouseProjectCard firstAndLastName={firstAndLastName} warehouseProject={project} projectId={project?.projectId} projectIndex={index} clientId={clientId}/>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        warehouse: state.warehouse
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        warehouseActions: bindActionCreators(warehouseActions as any, dispatch),
    }
}


export default (connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateWarehouseForm)));