import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as transportActions from '../../actions/transport';
import * as installationActions from '../../actions/installation';
import "./Installation.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import Select from "react-select";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import Tooltip from "rc-tooltip";
import calendarIcon from "../../assets/icons/blackCalendar.svg";
import moment from "moment";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {
    createInstallationRecord,
    editInstallationRecord,
    getInstallationRecordByProjectId, getProjectsReadyForInstallation
} from "../../actions/installation";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {FileUpload} from "../../types/fileUpload.type";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import PICFile from "../../assets/icons/pic.svg";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    installation: any
    installationActions: any
    history: any
    buttonLoader: boolean
    permissions: any
}

const CreateInstallationForm = (props: Props) => {

    const { projectId } = useParams<ParameterTypes>();
    const [position, setPosition] = useState<LatLngExpression>();
    const [comment, setComment] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [projects, setProjects] = useState<any>()
    const [allInstallationFiles, setAllInstallationFiles] = useState<any>()
    const [selectedProject, setSelectedProject] = useState<any>([])
    const [installerSignature, setInstallerSignature] = useState<FileUpload>({} as FileUpload);
    const [clientSignature, setClientSignature] = useState<FileUpload>({} as FileUpload);
    const [installerPictures, setInstallerPictures] = useState<FileUpload[]>([] as FileUpload[]);

    useEffect(()=>{
        getCurrentPosition()
        props.installationActions.getProjectsReadyForInstallation()
    },[])

    useEffect(()=>{
        let tempArray = []
        props?.installation?.projectsForInstallation?.forEach((record)=>{
            tempArray.push({
                value: record?.id,
                label: record?.projectName,
                coordinates: record?.measureLocation ? record?.measureLocation?.split(',') : record?.projectLocation?.split(',')
            })
        })
        setProjects(tempArray)
    },[props?.installation?.projectsForInstallation])

    useEffect(()=>{
        if(projectId){
            props?.installationActions.getInstallationRecordByProjectId(projectId)
        }else{
            resetFields()
        }
    },[projectId])

    useEffect(()=>{
        if(props?.installation?.installationRecordByProjectId){
            setSelectedProject({
                value: props?.installation?.installationRecordByProjectId?.project?.value,
                label: props?.installation?.installationRecordByProjectId?.project?.label,
                coordinates: props?.installation?.installationRecordByProjectId.project?.projectLocation ?  props?.installation?.installationRecordByProjectId.project?.projectLocation?.split(',') : ''
            })
            setStartDate(props?.installation?.installationRecordByProjectId?.installationStart)
            setEndDate(props?.installation?.installationRecordByProjectId?.installationEnd)
            setComment(props?.installation?.installationRecordByProjectId.comment)
            let tempFileArray = []
            props?.installation?.installationRecordByProjectId?.installationFiles?.forEach((file)=>{
                if(file?.installationFileWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.installationFileWithUrl, name: file?.installationFileName})
                }else if(file?.clientSignatureWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.clientSignatureWithUrl, name: file?.clientSignatureFileName})
                }else if(file?.installerSignatureWithUrl){
                    tempFileArray.push({id: file.id, fileUrl: file?.installerSignatureWithUrl, name: file?.installerSignatureFileName})
                }
            })
            setAllInstallationFiles(tempFileArray)
        }
    },[props?.installation?.installationRecordByProjectId])

    function resetFields() {
        setComment('')
        setInstallerPictures([])
        setInstallerSignature({} as FileUpload)
        setClientSignature({} as FileUpload)
        setStartDate('')
        setEndDate('')
        props.history.push('/dashboard/installation/create')
    }

    function saveInstallation(){
        let formData = new FormData()

        if(selectedProject.value){
            formData.append('projectId', selectedProject.value)
        }else{
            toast.error('Duhet te zgjidhni projektin!')
            return
        }

        if(startDate){
            formData.append('installationStart', startDate)
        }else{
            toast.error('Data e nisjes eshte obligative!')
            return
        }
        formData.append('installationEnd', endDate)
        if(installerSignature?.file){
            formData.append('installerSignature[]', installerSignature.file)
        }
        if(clientSignature?.file){
            formData.append('clientSignature[]', clientSignature.file)
        }
        if(installerPictures){
            installerPictures?.forEach((pic)=>{
                formData.append('installationFiles[]', pic.file)
            })
        }
        formData.append('comment', comment || '')
        if(projectId){
            props.installationActions?.editInstallationRecord(formData, props?.installation?.installationRecordByProjectId?.id)
        }else{
            props.installationActions?.createInstallationRecord(formData).then(()=>{
                props.history.push('/dashboard/installation')
            })
        }
    }

    function clearDate(which) {
        if(which === 'start'){
            setStartDate('')
        }else{
            setEndDate('')
        }
    }

    function getCurrentDate(which) {
        let currentDate = moment().format("YYYY-MM-DD")
        if(which === 'start'){
            setStartDate(currentDate)
        }else{
            setEndDate(currentDate)
        }
    }

    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    function createInstallationForm() {
        return (
            <div className={'w-100 mb-5 installationContainer'}>
                <h1 className={'py-3'}>Instalimi</h1>
                {selectedProject?.value && position && <div className="mapContainer">
                    <MapContainer
                        center={position}
                        zoom={13}
                        scrollWheelZoom={false}
                        className={'map'}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {selectedProject?.coordinates?.length === 2 && <Marker
                            position={selectedProject?.coordinates}
                            icon={icon}
                        >
                            <Popup>
                                <p className={'mb-0'}>{selectedProject?.label}</p>
                                {selectedProject?.coordinates[0] && <a target={'_blank'}
                                                                       href={`https://www.google.com/maps/?q=${selectedProject?.coordinates[0]},${selectedProject?.coordinates[1]}`}
                                                                       className={'mb-0 googleMapsLink'}>Shiko ne Google
                                    maps</a>}
                            </Popup>
                        </Marker>}
                    </MapContainer>
                </div>}
                <div className={'projectMultiSelectContainer'}>
                    <Select
                        id={`projectMultiSelect`}
                        name="projectMultiSelect"
                        options={projects}
                        closeMenuOnSelect={false}
                        className={'assigneeSelect'}
                        classNamePrefix="react-select"
                        isDisabled={projectId ? true : false}
                        value={selectedProject || ''}
                        onChange={(value) => setSelectedProject(value)}
                        placeholder={translatedText({
                            id: "client.order",
                            defaultMessage: "Projektet qe doni ti zgjidhni per instalim"
                        })}/>
                </div>
                <div className={`d-flex justify-content-between ${isMobile? 'flex-column':'mb-5'}`}>
                    <div className={`${isMobile? 'w-100': 'w-50'} pr-15`}>
                        <div className={'allInstallationFiles'}>
                            {allInstallationFiles && allInstallationFiles.map((item, index)=>{
                                return (
                                    <div className="d-flex align-items-center mb-2 cursor-pointer position-relative" key={index}>
                                        <img className="product-pic m-1" src={PICFile} alt="pic"/>
                                        <a target={'_blank'} href={item?.fileUrl} className="link-pics">{item?.name}</a>
                                    </div>
                                )
                            })}
                        </div>
                        <MikaFileInput labelKey={translatedText({
                            id: "offer.addFinalOffer",
                            defaultMessage: "Shto fotot e instalimit"
                        })} accept="image/*" multiple setFiles={setInstallerPictures} className={'mt-3 w-100 p-3'}/>
                    </div>
                    <div className={`${isMobile ? 'w-100':'w-50'}`}>
                        <Input
                            name="comments"
                            label="label"
                            id="comments"
                            defaultValue=''
                            inputWrapperClass={"w-100 textarea-wrapper"}
                            type="textarea"
                            value={comment || ''}
                            onChange={(name, value) => setComment(value)}
                        >
                            <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="Komenti"/></p>
                        </Input>
                    </div>
                </div>
                <div className={`d-flex ${isMobile ? 'flex-column': ''}`}>
                    <div className={`${isMobile? 'w-100':'w-25 pr-12'} d-flex align-items-end`}>
                        <Input
                            name="orderAcceptanceDate"
                            label="label"
                            id="orderAcceptanceDate"
                            inputWrapperClass={"m-0 w-100 startEndInstallationInputs"}
                            type="text"
                            inputTextType={EInputTextType.SimpleInput}
                            value={startDate || ''}
                            disabled
                        >
                            <p className={'m-0'}>Data e fillimit te instalimit</p>
                            <div className={'clearDate'}>
                                <p className={'x-sign'} onClick={()=>clearDate('start')}>+</p>
                            </div>
                            {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                      overlay={<span>Ruaj daten</span>}>
                                <img src={calendarIcon} alt=""
                                     className="pinLogoForMeasure"
                                     onClick={() => getCurrentDate('start')}/>
                            </Tooltip>}
                        </Input>
                    </div>
                    <div className={`${isMobile? 'w-100':'w-25 pl-12'} d-flex align-items-end`}>
                        <Input
                            name="orderAcceptationDate"
                            label="label"
                            id="orderAcceptationDate"
                            inputWrapperClass={"m-0 w-100 startEndInstallationInputs"}
                            type="text"
                            inputTextType={EInputTextType.SimpleInput}
                            value={endDate || ''}
                            disabled
                        >
                            <p className={'m-0'}>Data e perfundimit te instalimit</p>
                            <div className={'clearDate'}>
                                <p className={'x-sign'} onClick={()=>clearDate('end')}>+</p>
                            </div>
                            {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                      overlay={<span>Ruaj daten</span>}>
                                <img src={calendarIcon} alt=""
                                     className="pinLogoForMeasure"
                                     onClick={() => getCurrentDate('end')}/>
                            </Tooltip>}
                        </Input>
                    </div>
                    <div className={`${isMobile ? 'w-100':'w-25'} d-flex align-items-end file-input-wrapper`}>
                        <MikaFileInput labelKey={translatedText({
                            id: "offer.addFinalOffer",
                            defaultMessage: "Nenshkrimi i instaluesit"
                        })} setFiles={setInstallerSignature} className={'mt-3 w-100 p-3'}/>
                    </div>
                    <div className={`${isMobile ? 'w-100':'w-25'} d-flex align-items-end file-input-wrapper`}>
                        <MikaFileInput labelKey={translatedText({
                            id: "offer.addFinalOffer",
                            defaultMessage: "Nenshkrimi i klientit"
                        })} setFiles={setClientSignature} className={'mt-3 w-100 p-3'}/>
                    </div>
                </div>
                <div className={'w-100 d-flex justify-content-end'}>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveInstallation}
                        className="my-5 w-50"
                        buttonType={EButtonType.PurpleButton}>
                        <TranslatedText id="client.editContact"
                                        defaultMessage="Ruaj"/>
                    </Button>}
                </div>
            </div>
        )
    }

    let icon = L.icon({
        iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })


    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> : createInstallationForm()}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        installation: state.installation,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateInstallationForm as any)))