import {bindActionCreators, Dispatch} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import './admin.scss'
import TranslatedText from "../../components/TranslatedText";

interface Props {
    isNavOpen?: any
}


const AdminNav = (props: Props) => {

    return (
        <div className="admin-nav">
            <Link to="/admin">
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.allUsers"
                                                                                    defaultMessage="Te gjithe perdoruesit"/></span>}
                </div>
            </Link>
            <Link to="/admin/create-user">
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.createUser"
                                                                                    defaultMessage="Krijo perdorues"/></span>}
                </div>
            </Link>
            <Link to='/admin/departments'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.productionProcesses"
                                                                                    defaultMessage="Repartet e prodhimit"/></span>}
                </div>
            </Link>
            <Link to='/admin/responsibilities'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.productionProcesses"
                                                                                    defaultMessage="Proceset e prodhimit"/></span>}
                </div>
            </Link>
            <Link to='/admin/equipments'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.equipments" defaultMessage="Pajisjet"/></span>}
                </div>
            </Link>
            <Link to='/admin/units'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.units" defaultMessage="Njesit"/></span>}
                </div>
            </Link>
            <Link to='/admin/materials'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.materials" defaultMessage="Materialet"/></span>}

                </div>
            </Link>
            <Link to='/admin/articles'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.articles" defaultMessage="Artikujt"/></span>}
                </div>
            </Link>
            <Link to='/admin/suppliers'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.articles" defaultMessage="Furnitoret"/></span>}
                </div>
            </Link>
            <Link to='/admin/measurement-sketch-templates'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.measurementSketchTemplates"
                                                                                    defaultMessage="Template per matje"/></span>}
                </div>
            </Link>
            <Link to='/admin/color-palettes'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.colorTypes"
                                                                                    defaultMessage="Llojet e ngjyrave"/></span>}
                </div>
            </Link>
            <Link to='/admin/offer-fields'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>

                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerFields"
                                                                                    defaultMessage="Fushat e ofertes"/></span>}
                </div>
            </Link>
            <Link to='/admin/offer-conditions'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>
                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerConditions"
                                                                                    defaultMessage="Kushtet e ofertes"/></span>}
                </div>
            </Link>
            <Link to='/admin/production-settings'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>
                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.projectSettings"
                                                                                    defaultMessage="Preferencat e prodhimit"/></span>}
                </div>
            </Link>
            <Link to='/admin/impression-questions'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>
                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.offerFields"
                                                                                    defaultMessage="Pyetjet e pershtypjes"/></span>}
                </div>
            </Link>
            <Link to='/admin/audit-log'>
                <div className="d-flex position-relative align-items-center">
                    <div className={`circle ${props?.isNavOpen ? 'closed' : ''}`}></div>
                    {!props?.isNavOpen && <span className="title"><TranslatedText id="app.header.auditLog"
                                                               defaultMessage="Audit Log"/></span>}
                </div>
            </Link>
        </div>
    )

}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminNav as any)))