import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './Installation.scss'
import * as productionActions from '../../actions/production'
import * as installationActions from '../../actions/installation'
import TableBbros from '../../components/Table/TableBbros';
import {
    INSTALLATION_RECORDS_COLUMNS,
    INSTALLATION_RECORDS_COLUMNS_MOBILE,
    OPTIONS
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import * as transportActions from "../../actions/transport";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";


interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
    transportActions: any
    transport: any
    archiveRecords: any
    installationActions: any
    installation: any
}

const AllInstallationRecords = (props: Props) => {

    const [installationRecordId, setInstallationRecordId] = useState<number>(null)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        props.installationActions.getInstallationRecords()
    }, [])

    const editInstallationData = (installationRecord) => {
        props.history.push({pathname: `/dashboard/installation/create/${installationRecord?.project?.value}`})
    }

    const handleRemove = (installationRecord) => {
        setDeleteModal(true)
        setInstallationRecordId(installationRecord?.id)
    }

    const deleteInstallation = () => {
        props.transportActions.deleteTransportRecord(installationRecordId).then(()=>{
            props.installationActions.getInstallationRecords()
        })
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event, rowData) => editInstallationData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData) => handleRemove(rowData)
        }
    ]

    return(
        <div className="wrapper warehouseContainer">
            <TableBbros
                title={translatedText({id: "table.warehouse", defaultMessage: "Instalimi"})}
                data={props?.installation?.installationRecords}
                columns={isMobile ? INSTALLATION_RECORDS_COLUMNS_MOBILE : INSTALLATION_RECORDS_COLUMNS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteInstallation()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
        transport: state.transport,
        installation: state.installation
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllInstallationRecords as any)));