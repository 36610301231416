import React, {useEffect, useState} from 'react';
import './calculateOfferModal.scss'
import {EInputTextType, Input} from '../../../components/Input/Input'
import PlusIconLink from '../../../assets/icons/plus.svg'
import FileIcon from '../../../assets/icons/file-text.svg'
import {FormattedMessage, injectIntl} from 'react-intl'
import Product from './Product'
import {withRouter} from 'react-router';
import {RootState} from '../../../reducers';
import {connect} from 'react-redux';
import {Dispatch, bindActionCreators} from 'redux';
import * as actions from '../../../actions/generalActions';
import * as productionActions from '../../../actions/production'
import * as offerActions from '../../../actions/offers';
import {OfferProduct} from '../../../types/offerProduct.type'
import {Button} from '../../../components/Button'
import ReactToPrint from 'react-to-print';
import {PrintedContent} from './PrintedContent';
import {useRef} from 'react'
import ArticleListAndCreateForm from '../../Admin/Article/ArticleListAndCreateForm'
import xSign from '../../../assets/icons/xSign.svg'
import * as Yup from "yup";
import * as validationConfigs from '../../../utils/validationConfigs';
import FormikFormWrapper from '../../../components/FormikFormWrapper/FormikFormWrapper'
import {axiosCaller} from "../../../utils/apiCaller";
import Spinner from "../../../components/Spinner";
import {toFixedNumber} from '../../../utils/react'
import Tooltip from 'rc-tooltip';
import {ModalContent} from '../../../types/modalContent.type';
import {formatDate} from '../../../utils/variableEvaluation';
import moment from "moment";
import {toast} from "react-toastify";
import {isMobile} from "../../../constants/constants";
import DifferentColoredPlus from "../../../assets/icons/differentColoredPlus.svg";
import HotkeyButton from "../../../components/HotkeyButton/HotkeyButton";
import MikaSelect from "../../../components/BbrosSelect/mikaSelect.component";
import {SelectOption} from "../../../types/selectOption.type";
import TranslatedText from "../../../components/TranslatedText";

interface Props {
    history: any;
    offers: any;
    offerActions: any;
    client: any;
    actions: any;
    intl: any;
    location: any;
    closeModalFunction: (boolean) => void
    permissions: any
    pricesIncluded: boolean
    buttonLoader: boolean
    getProductsOfClient: any
    products: any,
    setProducts: any
    productsLoading: any
    setProductsLoading: any
    productionActions: any
}

const CalculateOfferModal = (props: Props) => {
    const componentRef = useRef();

    const [firstAndLastName, setFirstAndLastName] = useState<string>()
    const [date, setDate] = useState(new Date())
    const [fiscalNumber, setFiscalNumber] = useState<number>()
    const [address, setAddress] = useState<string>()
    const [offerId, setOfferId] = useState<string>()
    const [accountNumber, setAccountNumber] = useState<number>()
    const [productDiscount, setProductDiscount] = useState<number>(null)
    const [comment, setComment] = useState<string>()
    const [total, setTotal] = useState<number>()
    const [discountedTotal, setDiscountedTotal] = useState<number>()
    const [discountType, setDiscountType] = useState<SelectOption>({} as SelectOption)
    const [discountValue, setDiscountValue] = useState<number>()
    const [discountRapport, setDiscountRapport] = useState<any>('')
    const [articleCreationModalOpened, setArticleCreationModalOpened] = useState<boolean>(false)
    const [initialValues] = useState({
        firstAndLastName: '',
        offer: '',
    })
    const [updatedStartDate, setUpdatedStartDate] = useState<any>()
    const [updatedEndDate, setUpdatedEndDate] = useState<any>()

    const [updateDatesPayload, setUpdateDatesPayload] = useState({
        startDate: '',
        endDate: '',
        articleId: '',
        productId: '',
        quantity: ''
    })

    useEffect(() => {
        props.actions.fetchArticles()
        setFirstAndLastName(`${props.client?.client?.firstnameAndLastname} ${props.client?.client?.lastName}`)
        setFiscalNumber(props.client?.client?.fiscalNumber)
        setAddress(props.client?.client?.address)
        setOfferId(props.offers?.offerClient?.offerId || props.offers?.offerClient?.id)
        setDate(props.offers?.offerClient?.offerDate)
        setAccountNumber(props.client?.client?.bankAccountNumber)
        setTotal(props.offers?.offerClient?.offerTotalCalculation)
        setComment(props.offers.offerClient.comment ? props.offers.offerClient.comment : '')

        if(props.offers.offerClient?.discountType === 0){
            setDiscountType({label: 'Fikse', value: 0})
        }
        if(props.offers.offerClient?.discountType === 1){
            setDiscountType({label: 'Perqindje', value: 1})
        }
        setDiscountValue(props.offers.offerClient?.discountValue)
    }, [])

    useEffect(()=>{
        if(updateDatesPayload?.startDate && updateDatesPayload?.endDate){
            setUpdatedStartDate(updateDatesPayload?.startDate)
            setUpdatedEndDate(updateDatesPayload?.endDate)
        }
    },[updateDatesPayload])

    const clientOffer: ModalContent = {
        firstAndLastName,
        date,
        fiscalNumber,
        address,
        invoiceNumber: offerId,
        accountNumber,
        products: props?.products,
        comment,
        offerTotalCalculation: total
    }



    const saveOffer = () => {
        let typesArray = []
        let taxArray = []
        let taxPriceArray = []
        let priceArray = []
        let productUnitArray = []
        let discountArray = []
        let designationArray = []
        let startArray = []

        let isNotValid = false
        if(props?.products?.length !== 0){
            props?.products.forEach((product)=>{
                if(!product?.id){
                    isNotValid = true
                }
            })
        }
        if(isNotValid){
            toast?.info('Ruani produktet para se ta ruani oferten!')
            return
        }
        props?.products.forEach((product) => {
            typesArray.push(product.type)
            taxArray.push(product.tax)
            taxPriceArray.push(product.taxPrice)
            priceArray.push(product.price)
            productUnitArray.push(product.unit)
            discountArray.push(product.discount)
            designationArray.push(product.designation)
            startArray.push(product.start)
        })

        props.offerActions.setNameSurnameOffer(firstAndLastName)
        props.offerActions.setDateOffer(formatDate(date))
        props.offerActions.setFiscalNumberOffer(fiscalNumber)
        props.offerActions.setAddress(address)
        props.offerActions.setOfferId(offerId)
        props.offerActions.setAccountNumber(accountNumber)
        props.offerActions.setDiscountValue(discountValue)
        props.offerActions.setDiscountType(discountType)
        props.offerActions.setTypes(typesArray)
        props.offerActions.setTax(taxArray)
        props.offerActions.setTaxPrice(taxPriceArray)
        props.offerActions.setDesignation(designationArray)
        props.offerActions.setDiscount(discountArray)
        props.offerActions.setProductUnit(productUnitArray)
        props.offerActions.setStartDate(startArray)
        props.offerActions.setPrice(priceArray)
        props.offerActions.setProducts(props?.products)
        props.offerActions.setComment(comment)
        props.offerActions.setDiscountTotal(discountedTotal)
        props.closeModalFunction(false)
    }

    const addEmptyOfferProduct = () => {
        let temporaryProductsCopy = [...props?.products]

        temporaryProductsCopy.push({
            designation: {
                label: '',
                value: 0
            },
            discount: 0,
            price: 0,
            tax: 0,
            taxPrice: 0,
            type: '',
            unit: 0,
            value: 0
        })
        props?.setProducts(temporaryProductsCopy)
        props.offerActions.setProducts(props?.products)
    }

    useEffect(() => {
        let allProductTotalPrice = 0
        if (props?.products) {
            props?.products.forEach((product) => {
                if (product.totalPrice) {
                    allProductTotalPrice += Number(product.totalPrice)
                }
            })
        }
        setTotal(allProductTotalPrice)
    }, [props?.products])

    function validationScheme() {
        return Yup.object().shape({
            firstAndLastName: validationConfigs.NAME_SURNAME_VALIDATION,
            offer: validationConfigs.PRODUCTS_VALIDATION,
        });
    }

    function saveStartAndEndDate() {
        if(moment(updatedStartDate) < moment()){
            toast.error('Data e fillimit nuk duhet te jete ne te kaluaren!')
            return;
        }

        if(moment(updatedEndDate) < moment(updatedStartDate)){
            toast.error('Data e mbarimit nuk duhet te jete para se te filloi!')
            return;
        }

        if (!updatedStartDate) {
            toast.error('Ju lutem mbushni daten e fillimit')
            return
        }

        let payload = {
            productId: Number(updateDatesPayload?.productId),
            start: updatedStartDate ? moment(updatedStartDate).format('YYYY-MM-DD') : '',
            end: updatedEndDate ? moment(updatedEndDate).format('YYYY-MM-DD') : ''
        }

        props.productionActions.updateProductDates(payload).then((res)=>{
            if (res.data) {
                toast.success('Datat u ndryshuan me sukses!')
                setUpdateDatesPayload({
                    startDate: '',
                    endDate: '',
                    articleId: '',
                    productId: '',
                    quantity: ''
                })
                props?.getProductsOfClient()
            } else {
                toast.error('Diqka shkoi gabim!')
            }
        })
    }

    useEffect(()=>{
        if(discountValue){
            if(discountType?.value === 0){
                let tempTotal = total - discountValue
                setDiscountedTotal(tempTotal)
                let temp = (discountValue * 100)/total
                setDiscountRapport(`- ${temp?.toFixed(2)} %`)
                setProductDiscount(+discountValue/ props?.products?.length)
            }
            if(discountType?.value === 1){
                let tempPercentageDiscount = total/100 * discountValue
                setDiscountedTotal(total - tempPercentageDiscount)
                setDiscountRapport(`- ${tempPercentageDiscount?.toFixed(2)}`)
                setProductDiscount(+tempPercentageDiscount/ props?.products?.length)
            }
        }
    },[discountValue, discountType, total])


    return (
        <div className="calculate-offer-modal-background">
            <div className="card position-relative">
                <div className="d-flex justify-content-between position-relative">
                    <h1 className="modal-title">
                        <TranslatedText id="table.nameSurname" defaultMessage="KRIJO OFERTE TE RE"/>
                    </h1>
                    <div className="d-flex print-doc align-items-center print-container">
                        <Tooltip
                            placement="right"
                            overlay={
                                <span>
                                    <TranslatedText id="app.clearForm"
                                                      defaultMessage="Ruaj se pari oferten pastaj printo"/>
                                </span>
                            }>
                            <ReactToPrint
                                trigger={() => <button disabled={props.offers.length > 0}
                                                       className="printButton d-flex align-items-center">
                                    <img src={FileIcon} alt="" className="mx-2"/>
                                    <p className="m-0">
                                        <TranslatedText id="app.table.nameSurname"
                                                          defaultMessage="Printo dokumentin"/>
                                    </p>
                                </button>}
                                content={() => componentRef.current}
                            />
                        </Tooltip>
                        <PrintedContent
                            offer={clientOffer}
                            message={props.offers.offerMessage}
                            ref={componentRef}
                        />
                    </div>
                    <HotkeyButton
                        keyCode="Escape"
                        modifiers={[]}
                        isActive={false}
                        onStateChange={(state) => {
                            if(state){
                                props.closeModalFunction(false)
                            }
                        }}
                    >
                        <div  className={'x-sign'}>
                            <img src={DifferentColoredPlus} alt="" onClick={() => {
                                props.closeModalFunction(false)
                            }}/>
                        </div>
                    </HotkeyButton>
                </div>
                <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()}
                                   onSubmit={saveOffer}>
                    <div>
                        <div>
                            <div>
                                <p className="list-title">
                                    <TranslatedText id="table.nameSurname" defaultMessage="Konsumatori"/>
                                </p>
                            </div>
                            <div className='input-container firstRow'>
                                <Input
                                    name="firstAndLastName"
                                    label="label"
                                    id="commentsInput"
                                    value={firstAndLastName || ''}
                                    defaultValue=''
                                    type="text"
                                    disabled
                                    inputTextType={EInputTextType.SimpleInput}>
                                    <TranslatedText id="table.nameSurname" defaultMessage="Emri dhe mbiemri *"/>
                                </Input>
                                <Input
                                    name="date"
                                    value={date || ''}
                                    type="date"
                                    disabled
                                    inputWrapperClass={`simple-date-input mt-0 heightFitContent ${date ? " " : 'does-not-have-data'}`}>
                                    <TranslatedText id="client.scanDocument" defaultMessage="Data e ofertes *"/>
                                </Input>
                                <Input
                                    name="address"
                                    label="label"
                                    id="commentsInput"
                                    value={address || ''}
                                    defaultValue=''
                                    type="text"
                                    disabled
                                    inputTextType={EInputTextType.SimpleInput}>
                                    <TranslatedText id="table.address" defaultMessage="Adresa"/>
                                </Input>
                                <Input
                                    name="offerId"
                                    label="label"
                                    id="commentsInput"
                                    value={offerId || ''}
                                    defaultValue=''
                                    type="text"
                                    onChange={(name, value) => value > 0 ? setOfferId(value) : ()=>{}}
                                    inputTextType={EInputTextType.SimpleInput}>
                                    <TranslatedText id="app.table.offerId" defaultMessage="Nr. Ofertes *"/>
                                </Input>
                            </div>
                        </div>
                        {props.permissions.fullPrivileges && <div className="d-flex w-100 justify-content-start py-3">
                            <div className="d-flex" onClick={() => setArticleCreationModalOpened(true)}>
                                <h1 className="m-0 add-product">
                                    <TranslatedText id="table.addProduct" defaultMessage="SHTO ARTIKULLIN"/>
                                </h1>
                                <img src={PlusIconLink} alt="plus icon link" className="mx-3"/>
                            </div>
                        </div>}
                        {articleCreationModalOpened && <div className="card create-article-modal">
                            <div className="cursor-pointer" onClick={() => setArticleCreationModalOpened(false)}>
                                <img src={xSign} alt=""/>
                            </div>
                            <ArticleListAndCreateForm/>
                        </div>}
                        {props?.productsLoading ? <Spinner/> :
                            <div>
                                {props?.products && props?.products?.map((product, index) => (
                                    <Product data={product} getProductsOfClient={props?.getProductsOfClient}
                                             setUpdateDatesPayload={setUpdateDatesPayload}
                                             products={props?.products} setProducts={props?.setProducts}
                                             indexWhereThisProductIsPlacedInTheArray={index} key={index}
                                             discount={productDiscount}
                                    />
                                ))}
                            </div>
                        }
                        <div>
                            <div className="d-flex w-100 justify-content-start py-3">
                                <div className="d-flex" onClick={addEmptyOfferProduct}>
                                    <h1 className="m-0 add-product">
                                        <TranslatedText id="table.addProduct" defaultMessage="SHTO PRODUKTIN"/>
                                    </h1>
                                    <img src={PlusIconLink} alt="plus icon link" className="mx-3"/>
                                </div>
                            </div>
                            <div className='input-container comment-section row'>
                                <Input
                                    name="comment"
                                    label="label"
                                    inputWrapperClass='col-10 note'
                                    id="commentsInput"
                                    value={comment || ''}
                                    onChange={(name, value) => setComment(value)}
                                    defaultValue=''
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                >
                                    <span className="font-weight-bold">
                                        <TranslatedText id="table.notice" defaultMessage="Verejtje"/>
                                    </span>
                                </Input>
                                <Input
                                    name="comments"
                                    label="label"
                                    inputWrapperClass='col-2'
                                    id="commentsInput"
                                    value={(total && toFixedNumber(total, 2)) || ''}
                                    onChange={(name, value) => setTotal(value)}
                                    defaultValue=''
                                    type="number"
                                    inputTextType={EInputTextType.SimpleInput}
                                    disabled
                                >
                                    <span className="font-weight-bold">
                                        <TranslatedText id="table.total" defaultMessage="Totali"/>
                                    </span>
                                </Input>
                            </div>
                        </div>
                        <div className={'discountedTotalWrapper'}>
                            <div className={'discountElement'}>
                                <MikaSelect options={[{label: 'Fikse', value: 0},{label: 'Perqindje', value: 1}]} placeholder={'Menyra e zbritjes'} value={discountType} setEntireOptionFunction={setDiscountType} />
                            </div>
                            <div className={'discountElement'}>
                                <Input
                                    name="discountValue"
                                    label="label"
                                    id="commentsInput"
                                    value={discountValue || ''}
                                    onChange={(name, value) => discountType?.value !== null ? setDiscountValue(value) : ()=>{}}
                                    defaultValue=''
                                    type="number"
                                    inputTextType={EInputTextType.SimpleInput}
                                >
                                    <span className="font-weight-bold">
                                        <TranslatedText id="table.total" defaultMessage="Vlera e zbritur"/>
                                    </span>
                                </Input>
                            </div>
                            <div className={'discountedTotalContainer discountElement'}>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="commentsInput"
                                    value={(discountedTotal && toFixedNumber(discountedTotal, 2)) || '0'}
                                    onChange={(name, value) => setDiscountedTotal(value)}
                                    defaultValue=''
                                    type="number"
                                    inputTextType={EInputTextType.SimpleInput}
                                    disabled
                                >
                                    <span className="font-weight-bold">
                                        <TranslatedText id="table.total" defaultMessage="Totali me zbritje"/>
                                    </span>
                                </Input>
                                <p className={'discountValue'}>{discountRapport}</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end w-100 button-container">
                            {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                // submit={true}
                                onClick={saveOffer}
                                className={`${isMobile ? 'w-100' : 'w-25'} align-items-center mb-4 px-3 text-center calculateOfferButton`}>
                                <TranslatedText id="offer.saveOffer" defaultMessage="Ruaj Oferten"/>
                            </Button>}
                        </div>
                    </div>
                </FormikFormWrapper>
            </div>
            {updateDatesPayload?.productId && <div className={'updateStartDateModalContainer'}>
                <div className={'updateStartDateModal'}>
                    <Input
                        name="date"
                        value={ updatedStartDate ? new Date(updatedStartDate) : ''}
                        type="date"
                        onChange={(name, value) => setUpdatedStartDate(value)}
                        inputWrapperClass={`simple-date-input heightFitContent ${updatedStartDate ? " " : 'does-not-have-data'}`}>
                        <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit *"/>
                    </Input>
                    <Input
                        name="date"
                        value={ updatedEndDate ? new Date(updatedEndDate) : ''}
                        type="date"
                        onChange={(name, value) => setUpdatedEndDate(value)}
                        inputWrapperClass={`simple-date-input heightFitContent ${updatedEndDate ? " " : 'does-not-have-data'}`}>
                        <TranslatedText id="client.scanDocument" defaultMessage="Data e Ma *"/>
                    </Input>
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={() => setUpdateDatesPayload({
                            startDate: '',
                            endDate: '',
                            articleId: '',
                            productId: '',
                            quantity: ''
                        })}
                        className="saveStartDateButton calculateOfferButton">
                        <TranslatedText id="offer.saveOffer" defaultMessage="Anulo"/>
                    </Button>
                    <Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveStartAndEndDate}
                        className="saveStartDateButton calculateOfferButton">
                        <TranslatedText id="offer.saveDates" defaultMessage="Ruaj datat"/>
                    </Button>
                </div>
            </div>}
        </div>
    )
}


function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
        productionActions: bindActionCreators(productionActions as any, dispatch)
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(CalculateOfferModal as any)));
