import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import { wrap }  from './generalActions'
import {toast} from "react-toastify";

export const getServiceRecords = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `archive/service`).then(res => {
            dispatch({ "type": constants.FETCH_SERVICE_RECORDS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getProjectReadyForService = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing`).then(res => {
            dispatch({ "type": constants.FETCH_PROJECTS_FOR_SERVICE, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const createService = (payload) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `servicing`, payload).then(res => {
            toast.success('Servisimi u krijua me sukses');
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const editService = (payload, serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `servicing/${serviceId}`, payload).then(res => {
            toast.success('Servisimi u editua me sukses');
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const getService = (serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `servicing/${serviceId}`).then(res => {
            dispatch({ "type": constants.FETCH_SERVICE, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')

export const deleteServiceRecord = (serviceId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('DELETE', `servicing/${serviceId}`).then(res => {
            toast.info('Servisimi u fshi me sukses!')
        })
    } catch(err) {
    }
}, 'FETCH_EQUIPMENTS')