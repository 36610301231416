import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as agreementsActions from '../../actions/agreements';
import { withRouter } from 'react-router';
import './Agreements.scss'
import { Client } from '../../types/client.type';
import { Switch,Route } from 'react-router-dom';
import ShowAllAgreements from './ShowAllAgreements';
import CreateAgreementForm from './CreateAgreementForm';
import React, {useEffect} from "react";
import AllAgreements from "../Agreements/AllAgreements";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history:any;
    client:any;
    clientActions: any,
    actions: any,
    agreements: any
    agreementsActions: any,
    letter: string,
    clientsByModuleData: Array<Client>
}

const Agreements = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    }, [])

    return (
        <div className="agreements h-100">
            <Switch>
                <Route exact path="/dashboard/agreements/:statusId?" component={ShowAllAgreements} />
                <Route exact path="/dashboard/agreements/create/:agreementId?/:projectId?" component={CreateAgreementForm}/>
                <Route exact path="/dashboard/agreements/all-agreements/:clientId?" component={AllAgreements}/>
                <Route path="/dashboard/agreements/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        agreements: state.agreements
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        agreementsActions: bindActionCreators(agreementsActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Agreements as any)));