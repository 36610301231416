import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import { withRouter } from 'react-router';
import './warehouse.scss'
import * as productionActions from '../../actions/production'
import DeleteModalComponent from '../../components/Modal/DeleteModal';
import ModalComponent from '../../components/Modal/ModalComponent';
import TableBbros from '../../components/Table/TableBbros';
import {
    COLUMNS,
    COLUMNS_MOBILE,
    OPTIONS,
    WAREHOUSE_PRODUCTS,
    WAREHOUSE_PRODUCTS_MOBILE
} from '../../components/Table/TableConstants';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { ClientObject } from '../../types/client.type';
import {isMobile} from "../../constants/constants";
import {translatedText} from "../../components/TranslatedText";
import moment from "moment";


interface Props {
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    production: any,
    productionActions: any
    actions:any;
    letter: string;
    clientsByModuleData: any;
}

const AllWarehouseProducts = (props: Props) => {

    const moduleId = 6
    const moduleName = 'warehouse'
    const [clientId, setClientId] = useState<number>(null);
    const [warehouseProducts, setWarehouseProducts] = useState([])
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        props.productionActions.fetchWarehouseProducts()
    }, [])

    useEffect(() => {
        let tempWarehouseArray = [...props.production.warehouseProducts]
        tempWarehouseArray.sort(function compare(a, b) {
            let firstMilliseconds = new Date(b['finishedProduct']?.updatedAt).getTime();
            let secondMilliseconds = new Date(a['finishedProduct']?.updatedAt).getTime();
            return firstMilliseconds - secondMilliseconds;
        });
        tempWarehouseArray.forEach((warehouse, index)=>{
            tempWarehouseArray[index] = {
                ...tempWarehouseArray[index],
                finishedProduct: warehouse?.finishedProduct?.updatedAt ? moment(warehouse?.finishedProduct?.updatedAt).format("DD/MM/YYYY hh:mm:ss") : ''
            }
        })
        setWarehouseProducts(tempWarehouseArray)
    }, [props.production.warehouseProducts])

    const editMeasureData = (client: ClientObject) => {
        props.history.push({pathname: `/dashboard/warehouse/create/${client?.clientID}`})
    }

    function deleteWarehouse(client: ClientObject) {
        setClientId(client?.id)
        setDeleteModal(true)
    }

    const deleteAction = () => {
        props.actions.deleteModuleData(clientId, moduleName)
        setDeleteModal(false)
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editMeasureData(rowData)
        },
        {
            icon: DeleteIcon,
            tooltip: 'Delete',
            onClick: (event: Event, rowData:ClientObject) => deleteWarehouse(rowData)
        }
    ]

    return(
        <div className="wrapper warehouseContainer">
            <TableBbros
                className={'firstColumnThinner'}
                title={translatedText({id: "table.warehouseCondition", defaultMessage: "Gjendja e depos"})}
                data={warehouseProducts}
                columns={isMobile ? WAREHOUSE_PRODUCTS_MOBILE : WAREHOUSE_PRODUCTS}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteAction()} />
            </ModalComponent>
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        production: state.production,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        productionActions: bindActionCreators(productionActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(AllWarehouseProducts as any)));