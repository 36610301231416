import { TablePagination, TablePaginationProps } from "@material-ui/core";

export function PatchedPagination(props: TablePaginationProps) {
	const {
		ActionsComponent,
		onChangePage,
		onChangeRowsPerPage,
		...tablePaginationProps
	} = props;

	return (
		<TablePagination
			{...tablePaginationProps}
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
			ActionsComponent={(subprops) => {
				const { onPageChange, ...actionsComponentProps } = subprops;
				return (
					// @ts-expect-error ActionsComponent is provided by material-table
					<ActionsComponent
						{...actionsComponentProps}
						onChangePage={onPageChange}
					/>
				);
			}}
		/>
	);
}