import { toast } from 'react-toastify';
import * as constants from '../constants/constants';
import { apiCaller, axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading, setButtonLoading }  from './generalActions'
import client from "../reducers/client";
import {Project} from "../types/Project.type";


export const setEmail = (payload: string): ACTION => ({
    type: constants.SET_EMAIL,
    payload
});

export const setUserToDelete = (payload: number): ACTION => ({
    type: constants.SET_USER_TO_DELETE,
    payload
});

export const setDeletedState = (payload: boolean): ACTION => ({
    type: constants.SET_DELETED_STATE,
    payload
});

export const resetClient = (payload: any): ACTION => ({
    type: constants.RESET_CLIENT,
    payload
});

export const createClient = (payload: Object, letter: string, fromDate?: Date, toDate?: Date, currentPage?: number) => wrap((dispatch:any, state:any) => {
    try {
        const headers = {
            "Accept": "multipart/form-data",
            "Content-Type": "multipart/form-data"
        }
        return axiosCaller('POST', `clients`, payload, headers, true).then((response:any) => {
            if (response?.data?.unique) {
                toast.error('Ky klient ekziston!');
                return {}
            }
            if(response.data.data){
                dispatch({ "type": constants.CREATE_CLIENT, payload: response.data.data })
            }
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        throw new Error(err.message);
    }
}, 'CREATE_CLIENT_ERROR');

export const editClient = (id:number, payload:any) => wrap((dispatch:any, state:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        return axiosCaller('POST', `clients/${id}?_method=PATCH`, payload).then((response:any) => {
            dispatch({ "type": constants.EDIT_CLIENT, payload: response })
            toast.success('Klienti u ndryshua me sukses')
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return response
        });
    } catch(err) {
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
        toast.error('Diqka shkoi gabim!');
        throw new Error('Diqka nuk shkoi mire');
    }
}, 'EDIT_CLIENT_ERROR');


export const fetchClient = (clientId: number, moduleId:number) => wrap(async(dispatch:any) => {
    try {
        // dispatch(setLoading(true))
        // dispatch(setButtonLoading(true));Dont setLoader to true for this request
        return await apiCaller('GET', `clients/${clientId}`).then(res => {
            dispatch({ "type": constants.FETCHED_CLIENT, "payload": res });
            // dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        throw new Error('Diqka nuk shkoi mire');
    }
}, 'GET_CLIENT_ERROR')


export const fetchClients = (start?, end?) => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        //add date on get request api url
        return await apiCaller('GET', `clients/search${start && end ? `?start=${start}&end=${end}`:''}`).then(res => {
            dispatch({ "type": constants.FETCHED_CLIENTS, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_CLIENTS_ERROR')

export const getAllClients = () => wrap(async(dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));
        //add date on get request api url
        return await apiCaller('GET', `clients`).then(res => {
            dispatch({ "type": constants.FETCHED_CLIENTS, "payload": res });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));
            return res
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));
    }
}, 'GET_CLIENTS_ERROR')

export const deleteClient = (clientId:any) => wrap((dispatch:any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));;
        return apiCaller('DELETE', `clients/${clientId}`).then(res => {
            dispatch({"type": constants.DELETE_CLIENT, "payload": res})
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));;
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));;
        throw new Error('Diqka nuk shkoi mire')
    }
}, 'DELETE_CLIENT_ERROR')


export const exportClients = (letter: string, fromDate?: any, toDate?: any) => wrap(async(dispatch:any) => {
    try {
        
        return await apiCaller('GET', `clients/export-excel?from_date=${fromDate}&to_date=${toDate}&letter=${letter}`).then(res => {
            
            return res
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        
    }
}, 'EXPORT_CLIENTS_ERROR')

export const importClients = (payload:any) => wrap((dispatch:any) => {
    try {
        const headers = {
            "Accept": "multipart/form-data",
            "Content-Type": "multipart/form-data"
        }
        return axiosCaller('POST', 'clients/import-excel', payload, headers, true).then((response:any) => {
            dispatch({ "type": constants.IMPORT_CLIENTS, "payload": response });
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        
        throw new Error('Diqka nuk shkoi mire');
    }
}, 'IMPORT_CLIENTS_ERROR')

export const getClientProjects = (clientId: number, customFilter) => wrap(async (dispatch: any) => {
    try {
        dispatch(setLoading(true))
        dispatch(setButtonLoading(true));;
        return await axiosCaller('GET', `clients/${clientId}/projects${customFilter? '?customFilter=projectsWithoutMeasure':''}`).then((response) => {
            dispatch({ "type": constants.GET_CLIENT_PROJECTS, "payload": response.data?.data[0]?.projects });
            dispatch(setLoading(false))
            dispatch(setButtonLoading(false));;
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));;
    }
}, 'GET_CLIENT_PROJECTS')

export const saveClientProjects = (payload) => wrap(async (dispatch: any) => {
    try {
        dispatch({ "type": constants.GET_CLIENT_PROJECTS, "payload": payload });
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));;
    }
}, 'GET_CLIENT_PROJECTS')

export const createProjects = (payload) => wrap(async (dispatch: any) => {
    try {
        return await axiosCaller('POST', `projects`, payload).then((response) => {
            return response
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
        dispatch(setLoading(false))
        dispatch(setButtonLoading(false));;
    }
}, 'GET_CLIENT_PROJECTS')

export const editProject = (projectId, payload) => wrap(async (dispatch: any) => {
    try {
        return await axiosCaller('POST', `projects/${projectId}`, payload).then((response) => {
        })
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
    }
}, 'GET_CLIENT_PROJECTS')

export const deleteProject = (projectId) => wrap(async (dispatch: any) => {
    try {
        return await axiosCaller('DELETE', `projects/${projectId}`)
    } catch(err) {
        toast.error('Diqka shkoi gabim!');
    }
}, 'GET_CLIENT_PROJECTS')