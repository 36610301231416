import * as React from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from 'react-redux'
import {RootState} from '../../reducers'
import './Navbar.scss'
import {bindActionCreators, Dispatch} from 'redux'
import * as actions from '../../actions/generalActions'
import {withRouter} from 'react-router'
import {Link, Route} from 'react-router-dom';
import AdminNav from '../../containers/Admin/AdminNav';
import classNames from 'classnames';
import measureSvg from '../../assets/icons/measure.svg'
import productionSvg from '../../assets/icons/production.svg'
import contactSvg from '../../assets/icons/contact.svg'
import offerSvg from '../../assets/icons/offer.svg'
import warehouseSvg from '../../assets/icons/warehouse.svg'
import agreementSvg from '../../assets/icons/agreement.svg'
import transportSvg from '../../assets/icons/transport.svg'
import installationSvg from '../../assets/icons/installation.svg'
import serviceSvg from '../../assets/icons/service.svg'
import impressionSvg from '../../assets/icons/impression.svg'
import TranslatedText, {translatedText} from "../TranslatedText";

interface Props {
    step: number;
    actions: any;
    history: any;
    intl: any;
    isNavOpen: boolean;
    isAdminPanelOpen: boolean;
    permissions: any
}

interface State {
    navbar: string[],
}

class Navbar extends React.Component<Props, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            navbar: [
                translatedText({id: "contact", defaultMessage: "Kontakti"}),
                translatedText({id: "measure", defaultMessage: "Matja"}),
                translatedText({id: "offer", defaultMessage: "Oferta"}),
                translatedText({id: "agreement", defaultMessage: "Marreveshja"}),
                // translatedText({id:"contact", defaultMessage: "Projektimi"}),
                translatedText({id: "production", defaultMessage: "Prodhimi"}),
                translatedText({id: "warehouse", defaultMessage: "Depo"}),
                translatedText({id: "transport", defaultMessage: "Transporti"}),
                translatedText({id: "installation", defaultMessage: "Instalimi"}),
                translatedText({id: "service", defaultMessage: "Servisimi"}),
                translatedText({id: "impression", defaultMessage: "Pershtypja"}),
            ]
        }
    }

    render() {

        const icons = {
            contact: contactSvg,
            measures: measureSvg,
            offers: offerSvg,
            agreements:  agreementSvg,
            production: productionSvg,
            warehouse: warehouseSvg,
            transport: transportSvg,
            installation: installationSvg,
            service: serviceSvg,
            impression: impressionSvg
        }


        const steps = {
            0: "contact",
            1: "measures",
            2: "offers",
            3: "agreements",
            // 4: "blueprints",
            4: "production",
            5: "warehouse",
            6: "transport",
            7: "installation",
            8: "service",
            9: "impression"
        }

        function toggleNavBar(enter, navOpened, setNavOpened){
            if(localStorage.getItem("mikaNavbarToggled") !== 'true'){
                if(enter){
                    setNavOpened(false)
                }else{
                    setNavOpened(true)
                }
            }
        }

        let pathName = this.props.history.location.pathname.split("/")
        let activeModule = pathName[2]
        return (
            <div className={classNames("left-navbar", {
                'collapsed': this.props.isNavOpen
            })} onMouseEnter={() => toggleNavBar(true, this.props.isNavOpen, this.props?.actions.setNavBarOpen)} onMouseLeave={() => toggleNavBar(false, this.props.isNavOpen, this.props?.actions.setNavBarOpen)}>
                <Route path="/dashboard" render={() =>
                    <ul>
                        {this.state.navbar.map((name: string, index: number) => {
                                return (
                                    <li className={`row-li ${!this.props.isNavOpen && activeModule === steps[index] ? "active" : ""}`}
                                        key={index}>
                                        {(this.props?.permissions?.fullPrivileges || this.props?.permissions[steps[index]]) &&
                                        <Link to={`/dashboard/${steps[index]}`}>
                                            <div className="d-flex align-items-center">
                                                <div className="circle">
                                                    <img src={icons[steps[index]]} alt=""/>
                                                </div>
                                                <span className="border-circle"></span>
                                                <span className="title">{name}</span>
                                            </div>
                                        </Link>}
                                        {activeModule === steps[index] && Object.keys(this.props?.permissions).length !== 0 &&
                                        <div className={`d-flex sub-menu-container ${this?.props?.isNavOpen ? 'd-none   ': ''}`}>
                                            {steps[index] &&
                                            <ul className="sub-menu">
                                                {this.props?.permissions?.fullPrivileges && <Link to={`/dashboard/${steps[index]}`}>
                                                    <li>
                                                        <TranslatedText id="all"
                                                                          defaultMessage="Shiko te gjitha"/>
                                                    </li>
                                                </Link>}
                                                {steps[index] === 'contact' && <>
                                                    <Link to={`/dashboard/contact/contactsForMeasure`}>
                                                        <li>
                                                            <TranslatedText id="clientsForMeasure"
                                                                            defaultMessage="Klientat per matje"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/archive/${steps[index]}`}>
                                                        <li >
                                                            <TranslatedText id="archive"
                                                                              defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                    {/*<Link to={`/dashboard/${steps[index]}/2`}>*/}
                                                    {/*    <li>*/}
                                                    {/*        <TranslatedText id="archive"*/}
                                                    {/*                        defaultMessage="Arkiva"/>*/}
                                                    {/*    </li>*/}
                                                    {/*</Link>*/}
                                                </>}
                                                {steps[index] === 'measures' && <>
                                                    <Link to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="onHold"
                                                                            defaultMessage="Ne pritje"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="initial"
                                                                            defaultMessage="Fillestare"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Finale"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/passive`}>
                                                        <li>
                                                            <TranslatedText id="passive"
                                                                            defaultMessage="Pasive"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {steps[index] === 'offers' && <>
                                                    <Link to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="sentForConfirmation"
                                                                            defaultMessage="E derguar per konfirmim"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="rejected"
                                                                            defaultMessage="Te refuzuara"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/4`}>
                                                        <li>
                                                            <TranslatedText id="undefined"
                                                                            defaultMessage="Te padefinuara"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {steps[index] === 'agreements' && <>
                                                    <Link to={`/dashboard/${steps[index]}/1`}>
                                                        <li>
                                                            <TranslatedText id="onProductionHold"
                                                                            defaultMessage="Ne pritje per prodhim"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/2`}>
                                                        <li>
                                                            <TranslatedText id="onProductionProcess"
                                                                            defaultMessage="Ne process te prodhimit"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/3`}>
                                                        <li>
                                                            <TranslatedText id="inWarehouse"
                                                                            defaultMessage="Ne depo"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/5`}>
                                                        <li>
                                                            <TranslatedText id="cancelled"
                                                                            defaultMessage="E anuluar"/>
                                                        </li>
                                                    </Link>
                                                    <Link to={`/dashboard/${steps[index]}/4`}>
                                                        <li>
                                                            <TranslatedText id="archive"
                                                                            defaultMessage="Arkiva"/>
                                                        </li>
                                                    </Link>
                                                </>}
                                                {(this.props?.permissions?.fullPrivileges || this.props?.permissions[steps[index]]) &&
                                                <Link
                                                    to={`/dashboard/${steps[index]}/${steps[index] === 'warehouse' ? 'warehouseProducts' : 'create'}`}>
                                                    {steps[index] === 'production' ?
                                                        <li >
                                                            <TranslatedText id="productionInformation"
                                                                              defaultMessage="Info per prodhimtari"/>
                                                        </li>
                                                        :
                                                        <>
                                                            <>
                                                                {/*{steps[index] !== 'offers' && steps[index] !== 'agreements' && steps[index] !== 'measures' &&*/}
                                                                {/*<li >*/}
                                                                {/*    <TranslatedText id="app.navigation.createNew"*/}
                                                                {/*                      defaultMessage="Krijo te re"/>*/}
                                                                {/*</li>}*/}
                                                            </>
                                                        </>
                                                    }
                                                </Link>}
                                                {steps[index] === 'warehouse' &&
                                                    <>
                                                        <Link to={`/dashboard/${steps[index]}/inner-stock`}>
                                                            <li >
                                                                <TranslatedText id="app.navigation.createNew"
                                                                                defaultMessage="Produktet e blera"/>
                                                            </li>
                                                        </Link>
                                                        <Link to={`/dashboard/${steps[index]}/inner-stock`}>
                                                            <li>
                                                                <TranslatedText id="innerStock"
                                                                                defaultMessage="Stoku i brendshem"/>
                                                            </li>
                                                        </Link>
                                                        <Link to={`/dashboard/${steps[index]}/clients`}>
                                                            <li>
                                                                <TranslatedText id="clientsOnWarehouse"
                                                                                defaultMessage="Klientat ne depo"/>
                                                            </li>
                                                        </Link>
                                                    </>
                                                }
                                                {steps[index] === 'production' ? <>
                                                        {this.props?.permissions?.fullPrivileges && <Link to={`/dashboard/production/calendar`}>
                                                            <li>
                                                                <TranslatedText id="calendar"
                                                                                  defaultMessage="Kalendari"/>
                                                            </li>
                                                        </Link>}
                                                        <Link to={`/dashboard/production/archived-products`}>
                                                            <li >
                                                                <TranslatedText id="archivedProducts"
                                                                                  defaultMessage="Arkiva e produkteve"/>
                                                            </li>
                                                        </Link>
                                                        <Link to={`/dashboard/production/archived-responsibilities`}>
                                                            <li >
                                                                <TranslatedText id="archivedProcesses"
                                                                                  defaultMessage="Arkiva e proceseve"/>
                                                            </li>
                                                        </Link>
                                                        <Link to={`/dashboard/production/returned-processes`}>
                                                            <li >
                                                                <TranslatedText id="returnedProcesses"
                                                                                  defaultMessage="Proceset e kthyera"/>
                                                            </li>
                                                        </Link>
                                                        {this?.props?.permissions?.fullPrivileges && <Link to={`/dashboard/production/passive-products`}>
                                                            <li >
                                                                <TranslatedText id="passiveProducts"
                                                                                  defaultMessage="Produktet pasive"/>
                                                            </li>
                                                        </Link>}
                                                    </> :
                                                    <>
                                                        {(steps[index] !== 'service' && steps[index] !== 'impression' && steps[index] !== 'contact' && steps[index] !== 'measures' && steps[index] !== 'agreements' && steps[index] !== 'offers') &&
                                                        <Link to={`/dashboard/${steps[index]}/archive/${steps[index]}`}>
                                                            <li >
                                                                <TranslatedText id="archive"
                                                                                defaultMessage="Arkiva"/>
                                                            </li>
                                                        </Link>}
                                                        {(steps[index] === 'transport' || steps[index] === 'installation') &&
                                                        <Link to={`/dashboard/${steps[index]}/map`}>
                                                            <li >
                                                                <TranslatedText id="app.navigation.createNew"
                                                                                  defaultMessage="Harta"/>
                                                            </li>
                                                        </Link>
                                                        }
                                                    </>}
                                            </ul>
                                            }
                                        </div>
                                        }
                                    </li>
                                )
                            }
                        )}
                    </ul>
                }/>
                <Route path="/admin" render={() =>
                    <div className="admin-panel">
                        <AdminNav/>
                        <div className="go-to-system">
                            <ul>
                                <li className="row-li">
                                    <Link to='/dashboard/contact'>
                                        <div
                                            onClick={() => this.props.actions.openAdminPanel(false)}
                                            className="d-flex">
                                            <div className="circle"></div>
                                            <span className="border-circle"></span>
                                            { !this.props?.isNavOpen && <span className="title">
                                                <FormattedMessage id="app.goToSystem"
                                                                  defaultMessage="Kthehu tek sistemi"/>
                                            </span>}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                }/>
            </div>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        step: state.app.step
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Navbar)))