import {EInputTextType, Input} from '../../../components/Input/Input';
import {OfferProduct} from '../../../types/offerProduct.type';
import React, {useEffect, useState} from 'react';
import './calculateOfferModal.scss';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {RootState} from '../../../reducers';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import * as actions from '../../../actions/generalActions';
import * as offerActions from '../../../actions/offers';
import * as adminActions from '../../../actions/admin';
import DifferentColoredPlus from '../../../assets/icons/differentColoredPlus.svg';
import MikaSelect from '../../../components/BbrosSelect/mikaSelect.component';
import {ArticleInfomation} from '../../../types/ArticleInformation.type';
import {axiosCaller} from '../../../utils/apiCaller';
import {SelectOption} from '../../../types/selectOption.type';
import {Button} from '../../../components/Button'
import {toast} from "react-toastify";
import { formatNumberPrice, toFixedNumber } from '../../../utils/react';
import { formatDate } from '../../../utils/variableEvaluation';
import clearFormSvg from "../../../assets/icons/archeology.svg";
import Tooltip from "rc-tooltip";
import DeleteModalComponent from "../../../components/Modal/DeleteModal";
import ModalComponent from "../../../components/Modal/ModalComponent";
import TranslatedText, {translatedText} from "../../../components/TranslatedText";

interface Props {
    data?: OfferProduct;
    offers: any;
    offerActions: any;
    indexWhereThisProductIsPlacedInTheArray: number,
    products: OfferProduct[];
    setProducts?: (value: OfferProduct[] | ((prevVar: OfferProduct[]) => OfferProduct[])) => void;
    articles: ArticleInfomation[];
    intl: any;
    adminActions: any;
    admin: any;
    client: any;
    getProductsOfClient?: () => void,
    setUpdateDatesPayload?: (any) => void
    discount?: number,
    history?: any,
    permissions: any
}

const Product = (props: Props) => {
    
    const [product, setProduct] = useState<OfferProduct>({} as OfferProduct);
    const [calculatedTotalProductPrice, setCalculatedTotalProductPrice] = useState<number>(0);
    const [productTaxPrice, setProductTaxPrice] = useState<number>(0);
    const [selectedUnitName, setSelectedUnitName] = useState<number>();
    const [selectedWarehouseQuantity, setSelectedWarehouseQuantity] = useState<number>();
    const [selectedUnitId, setSelectedUnitId] = useState<number>();
    const [productCode, setProductCode] = useState<string>();
    const [articleId, setArticleId] = useState<number>();
    const [ forProduction, setForProduction ] = useState<boolean>(true)
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)
    const [isDeleteModalOpen, setDeleteModal] = useState<boolean>(false);
    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(null);

    useEffect(() => {
        setProduct({
            ...props.data,
        });
    }, []);

    useEffect(()=>{
        if(product?.designation?.value){
            axiosCaller('GET', `articles/${product?.designation?.value}/responsibilities`).then((res) => {
                setSelectedUnitName(res.data?.data?.unit?.name);
                setSelectedUnitId(res.data?.data?.unit?.id);
                setSelectedWarehouseQuantity(res.data?.data?.warehouse?.quantity)
                setProductCode(res.data?.data?.productCode);
            });
        }
    }, [product?.designation])

    const handleChange = (name, value) => {
        setProduct({
            ...product,
            totalPrice: calculatedTotalProductPrice,
            taxPrice: productTaxPrice,
            [name]: value,
        });
    };

    const removeProduct = (index: number) => {
        setSelectedProductIndex(index)
        setDeleteModal(true)
    };

    const deleteProduct = () => {
        let productsCopy = [...props.products];
        productsCopy.splice(selectedProductIndex, 1);
        props.setProducts(productsCopy);
        if(product?.id){
            axiosCaller('DELETE', `products/${product.id}`).then((res) => {
                if(res?.data?.finishedProduct){
                    toast.info('Produkti gjindet ne arkive, nuk mund te fshihet!')
                    return
                }
                if(res.data.data === true){
                    toast.success('Produkti u fshi me sukses!')
                    setDeleteModal(false)
                    props.getProductsOfClient()
                }
            });
        }
    };

    useEffect(() => {
        let allProductsCopy = [...props.products];
        let discountedPrice = Number(product.price) - (Number(product.price) * (Number(product.discount) / 100));
        let taxPrice = discountedPrice + (discountedPrice * (product.tax / 100));
        let totalProductPrice = Number(taxPrice) * Number(product.unit);
        setProductTaxPrice(taxPrice);
        setCalculatedTotalProductPrice(totalProductPrice);
        
        allProductsCopy[props.indexWhereThisProductIsPlacedInTheArray] = {
            ...product,
            totalPrice: totalProductPrice
        };
        props.setProducts(allProductsCopy);
    }, [product]);

    function saveArticleAndUnit(name: string, value: SelectOption) {
        axiosCaller('GET', `articles/${value?.value}/responsibilities`).then((res) => {
            setSelectedUnitName(res.data?.data?.unit?.name);
            setSelectedUnitId(res.data?.data?.unit?.id);
            setProductCode(res.data?.data?.productCode);
            setArticleId(value?.value)
            setForProduction(res.data?.data?.forProduction)
            handleChange(name, value);
        });
    }

    function saveProduct () {
        if(!(product?.designation?.value && product?.unit)){
            toast.info('Ju lutem mbushni te dhenat!')
            return
        }
        setButtonLoader(true)
        axiosCaller('GET', `products/agreements/start-end-date?articleId=${product.designation.value}&unitId=${selectedUnitId}&quantity=${product.unit}&clientId=${props.client?.client?.id}`).then(res => {
            setProduct({
                ...product,
                end: res.data.data?.end,
                start: res.data.data?.start
            });
            let payload = {
                type: productCode,
                designation: product?.designation?.value,
                quantity: product?.unit,
                price: product?.price,
                tax: product?.tax,
                taxPrice: productTaxPrice,
                start: res.data.data?.start,
                end: res.data.data?.end,
                discount: product?.discount,
                clientId: props?.client?.client?.id,
                offerId: null
            }
            if(props.offers?.offerClient?.id || props.offers?.offerClient?.id !== "undefined"){
                payload.offerId = props.offers?.offerClient?.id
            }
            if(product.id){
                axiosCaller('POST', `products${product.id}?_method=PATCH`, payload).then(()=>{
                    toast.success("Produkti u editua me sukses")
                    setButtonLoader(false)
                }).catch(()=>{
                    toast.error("Diqka nuk shkoi mire")
                })
            }else{
                axiosCaller('POST', `products`, payload).then((res)=>{
                    if(res?.data?.data){
                        setProduct(res?.data?.data)
                        props.setProducts([
                            ...props.products,
                            res?.data?.data
                        ])
                        toast.success("Produkti u ruajt me sukses")
                        setButtonLoader(false)
                    }
                }).catch(()=>{
                    toast.error("Diqka nuk shkoi mire")
                })
            }
        });
    }

    function manuallyUpdateStartAndEndDates () {
        props.setUpdateDatesPayload(
            {
                startDate: product?.start,
                endDate: product?.end,
                articleId: product.designation?.value,
                productId: product?.id,
                quantity: product?.unit
            }
        )
    }

    return (

        <div className="product-wrapper">
            <div>
                <div className="d-flex justify-content-between">
                    <p className="list-title">
                        <TranslatedText id="app.table.nameSurname" defaultMessage="Produkti"/>
                    </p>
                    <div className="x-sign" onClick={() => removeProduct(props.indexWhereThisProductIsPlacedInTheArray)}>
                        <img src={DifferentColoredPlus} alt="close" />
                    </div>
                </div>
                <div className="input-container">
                    <Tooltip placement="right" overlay={<span>{product.designation?.label}</span>}>
                        <div>
                            <MikaSelect
                                selectWrapperClassName="designation-select max-width-input mobile"
                                value={product.designation}
                                placeholder={translatedText({id: 'client.chooseArticle', defaultMessage: 'Artikujt'})}
                                name="designation"
                                setValueFunction={(name, value) => saveArticleAndUnit(name, value)}
                                options={props.articles}
                                disabled={product.id && true}
                                isOfferModalProduct
                            >
                                <TranslatedText id="table.article" defaultMessage="Artikulli"/>
                            </MikaSelect>
                        </div>
                    </Tooltip>

                    <Input
                        name="productCode"
                        label="label"
                        id="productCode"
                        inputWrapperClass="offerInputWrapper smallerInput medium mobile"
                        value={productCode || ''}
                        disabled
                        min={0}
                        defaultValue=""
                        type="text"
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Kodi i produktit"/>
                    </Input>

                    <Input
                        name="productCode"
                        label="label"
                        id="productCode"
                        inputWrapperClass="offerInputWrapper smallerWidth mobile"
                        value={selectedUnitName || ''}
                        disabled
                        defaultValue=""
                        type="text"
                        min={0}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Njesia"/>
                    </Input>

                    <Input
                        name="unit"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth position-relative mobile"
                        value={product.unit || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}
                        disabled={product.id && true}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Sasia"/>
                        {selectedWarehouseQuantity && <p data-toggle="tooltip" data-placement="left" title="Sasia ne depo:"
                            className={'warehouseQuantity'}>Ne stok: {selectedWarehouseQuantity}</p>}
                    </Input>

                    <Input
                        name="price"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.price), 2)  || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        min={0}
                        disabled={product.id && true}
                        type="number"
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Cmimi"/>
                    </Input>

                    <Input
                        name="discount"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.discount), 2)  || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}
                        disabled={product.id && true}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="app.table.nameSurname" defaultMessage="Zbritje %"/>
                    </Input>

                    <Input
                        name="tax"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput smallerWidth mobile"
                        value={formatNumberPrice(String(product.tax), 2) || ''}
                        onChange={(name, value) => handleChange(name, value)}
                        defaultValue=""
                        type="number"
                        min={0}
                        disabled={product.id && true}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="TVSH %"/>
                    </Input>
                    <Input
                        name="totalPrice"
                        label="label"
                        id="commentsInput"
                        inputWrapperClass="offerInputWrapper smallerInput taxPrice medium mobile discountInput"
                        value={toFixedNumber(calculatedTotalProductPrice, 2) || ''}
                        defaultValue=""
                        type="number"
                        disabled
                        min={0}
                        inputTextType={EInputTextType.SimpleInput}>
                        <TranslatedText id="table.nameSurname" defaultMessage="Totali"/>
                        <p className={'discountValue'}>{props?.discount ? `- ${props?.discount?.toFixed(2)}` : ''}</p>
                    </Input>
                    <Input
                        name="start"
                        label="label"
                        id="start"
                        inputWrapperClass="offerInputWrapper smallerInput startDate mobile"
                        value={(product?.start && formatDate(product?.start)) || ''}
                        defaultValue=""
                        // type="date"
                        disabled
                        inputTextType={EInputTextType.SimpleInput}>
                        {forProduction ?
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e fillimit te prodhimit"/>
                            :
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e porosise"/>
                        }
                    </Input>
                    <Input
                        name="end"
                        label="label"
                        id="end"
                        inputWrapperClass="offerInputWrapper smallerInput endDate mobile"
                        value={(product?.end && formatDate(product?.end)) || ''}
                        defaultValue=""
                        // type="text"
                        disabled
                        inputTextType={EInputTextType.SimpleInput}>
                        {forProduction ?
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e mbarimit te prodhimit"/>
                            :
                            <TranslatedText id="client.scanDocument" defaultMessage="Data e liferimit"/>
                        }
                    </Input>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) && <>
                        {!(product.id) ?
                            <Button
                                isLoading={buttonLoader}
                                isDisabled={buttonLoader}
                                onClick={saveProduct}
                                className="button-save-offer w-10  align-items-center calculateOfferButton py-2 text-center border-0">
                                <TranslatedText id="offer.saveOffer" defaultMessage="Ruaj Produktin"/>
                            </Button>
                            :
                            <Button
                                isLoading={buttonLoader}
                                isDisabled={buttonLoader}
                                onClick={manuallyUpdateStartAndEndDates}
                                className="button-save-offer w-10  align-items-center calculateOfferButton py-2 text-center border-0">
                                <TranslatedText id="offer.saveOffer" defaultMessage="Ndrysho datat"/>
                            </Button>
                        }
                    </>}
                </div>
                <hr className="mx-auto product-separation-line"/>
            </div>
            <ModalComponent
                isModalHeader
                isOpen={isDeleteModalOpen}
                close={setDeleteModal}
            >
                <DeleteModalComponent
                    history={props.history}
                    close={() => setDeleteModal(false)}
                    deleteFunction={() => deleteProduct()} />
            </ModalComponent>
        </div>
    );
};

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        offers: state.offers,
        admin: state.admin,
        client: state.client
    };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
    };
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Product as any)));
