import React, {ChangeEvent, CSSProperties, useEffect, useState} from 'react';
import './MikaFileInput.scss'
import PlusIconLink from '../../../assets/icons/plus.svg'
import {FileUpload} from "../../../types/fileUpload.type";
import {injectIntl} from "react-intl";
import {RootState} from "../../../reducers";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {values} from "lodash";
import {translatedText} from "../../TranslatedText";

interface Props{
    style?: CSSProperties;
    labelKey?: string;
    name?: string;
    flat?: boolean;
    value?: any;  //FileUpload | FileUpload[]
    bordered?: boolean;
    className?: string;
    multiple?: boolean
    setFiles: (FileUpload) => void;
    onChange?: () => void;
    intl: any,
    accept?: string,
}

const MikaFileInput: React.FC<Props> = (props: Props) => {

    const [fileLabel, setFileLabel] = useState<string>( props.labelKey ? props.labelKey :  translatedText({id:"app.noChosenFile", defaultMessage:"Asnje file i selektuar"}))
    const [showPics, setShowPics] = useState<boolean>(false)
    const [previewPics, setPreviewPics] = useState<any>()

    useEffect(() => {
        if (props.value?.fileName) {
            setFileLabel(props.value?.fileName)
        }else if(props?.multiple && props?.value && props?.value?.length !== 0){
            setFileLabel(`${props?.value && props?.value?.map((file)=>`${file?.fileName},`)}`)
            let tempArray = []
            for (const file of props?.value) {
                if(file?.file){
                    const objectUrl = URL?.createObjectURL(file?.file)
                    tempArray.push(objectUrl)
                }
            }
            setPreviewPics(tempArray)
        }else{
            setFileLabel(props.labelKey ? props.labelKey :  translatedText({id:"app.noChosenFile", defaultMessage:"Asnje dokument i selektuar"}))
        }
    }, [props.value])

    function setFileOnChange(files, e){

        if(files?.length !== 0){
            if(props.multiple){
                let allFiles = []
                for (let i = 0; i < files?.length; i++) {
                    allFiles.push({
                        file: files[i],
                        fileName: files[i].name
                    })
                }
                setFileLabel(`${allFiles?.length} ${translatedText({id:"app.filesSelected", defaultMessage:"file te selektuara"})}`)
                props.setFiles(allFiles)
            }else{
                setFileLabel(files[0].name)
                props.setFiles({
                    fileName: files[0].name,
                    file: files[0]
                })
            }
        }else{
            setFileLabel(props.labelKey ? props.labelKey : translatedText({id:"app.noChosenFile", defaultMessage:"Asnje file i selektuar"}))
        }
    }

    return <div className={`mikaInputFileWrapper ${props.className}`} onMouseEnter={()=>setShowPics(true)} onMouseLeave={()=>setShowPics(false)}>
        {showPics && previewPics?.length && <div className={'imgHoverContainer'}>
            {previewPics?.map((picBase64)=>{
                return <img src={picBase64} alt=""/>
            })}
        </div>}
        <input type="file" id={'fileInput'} name={props?.name} accept={props?.accept} onChange={(fileInput:ChangeEvent<HTMLInputElement>)=>{setFileOnChange(fileInput.target.files, fileInput)}} multiple={props.multiple} />
        <p className={'m-0'}>{ fileLabel }</p>
        <img src={PlusIconLink} alt="plus-icon" />
    </div>
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

export default withRouter(connect(mapStateToProps)(injectIntl(MikaFileInput)))