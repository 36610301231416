import { Assets } from './../types/assets.type';
import * as constants from "../constants/constants";

export interface AgreementsState {
    clientId: number;
    allClientAgreements: Array<any>;
    clientAgreement: any;
    createdAgreements: any;
    agreementAssets: Array<Assets>
}

const initialState = () => {
    return {
        clientId: null,
        allClientAgreements: [],
        clientAgreement: {},
        createdAgreements: {},
        agreementAssets: []
    }
}

const agreements = (state: AgreementsState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_ALL_CLIENT_AGREEMENTS: {
            return {
                ...state,
                allClientAgreements: action.payload
            }
        }
        case constants.FETCH_CLIENT_AGREEMENT: {
            return {
                ...state,
                clientAgreement: action.payload.data
            }
        }
        case constants.CREATE_AGREEMENTS: {
            return {
                ...state,
                createdAgreements: action.payload
            }
        }
        case constants.GET_AGREEMENT_ASSETS: {
            return {
                ...state,
                agreementAssets: action.payload
            }
        }
        case constants.RESET_ASSETS: {
            return {
                ...state,
                agreementAssets: []
            }
        }
    }
    return state;
}

export default agreements;