export const LOGIN = "LOGIN";
export const LOG_OUT = "LOG_OUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_USERNAME = "SET_USERNAME";
export const SET_EMAIL = "SET_EMAIL";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const IS_LOGIN = "IS_LOGIN";
export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const SET_STEP = "SET_STEP";

export const IS_LETTER_ACTIVE = "IS_LETTER_ACTIVE";
export const LOADING = 'LOADING';
export const BUTTON_LOADING = 'BUTTON_LOADING';
export const PARTIAL_LOADING = "PARTIAL_LOADING"
export const PERMISSIONS = "PERMISSIONS"
export const USER_RESPONSIBILITIES = "USER_RESPONSIBILITIES"
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const UPDATE_LOCALES = "UPDATE_LOCALES";
export const SHOW_VALIDATIONS = 'SHOW_VALIDATIONS';
export const FETCH_CLIENTS_BY_MODULE_DATA = 'FETCH_CLIENTS_BY_MODULE_DATA';
export const SET_CLIENT_TO_NEXT_MODULE = 'SET_CLIENT_TO_NEXT_MODULE';
export const SET_SELECTED_CLIENT_INDEX = 'SET_SELECTED_CLIENT_INDEX';
export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ARTICLES_FOR_PRODUCTION = 'FETCH_ARTICLES_FOR_PRODUCTION';
export const FETCH_ARTICLES_FOR_WAREHOUSE = 'FETCH_ARTICLES_FOR_WAREHOUSE';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';

export const PAGINAION_SIZE = "PAGINAION_SIZE";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_CITIES = "FETCH_CITIES";
export const FETCH_MATERIALS = "FETCH_MATERIALS";
export const GET_CLIENTS_WITH_STATUS_FILTER = "GET_CLIENTS_WITH_STATUS_FILTER"
export const IS_NAV_OPEN = "IS_NAV_OPEN";
export const IS_ADMIN_PANEL_OPEN = "IS_ADMIN_PANEL_OPEN";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const SET_PROJECT_NAME = "SET_PROJECT_NAME"
export const GET_ALL_PROJECT_INFO = "GET_ALL_PROJECT_INFO"
export const GET_PROJECT = "GET_PROJECT"
export const GET_PRODUCTION_SETTINGS = "GET_PRODUCTION_SETTINGS"
export const GET_MATERIALS_OF_PRODUCT = "GET_MATERIALS_OF_PRODUCT"

export const ALPHABET_LETTERS = ["all", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
export const SET_LANGUAGE = "SET_LANGUAGE";

//Client

export const SET_NAME_SURNAME = "SET_NAME_SURNAME";
export const SET_CLIENT_ADDRESS = "SET_CLIENT_ADDRESS";
export const SET_PHONE = "SET_PHONE";
export const SET_CLIENT_EMAIL = "SET_CLIENT_EMAIL";
export const SET_COMMENT = "SET_COMMENT";
export const SET_BUSINESS_NR = "SET_BUSINESS_NR";
export const SET_BANK_ACCOUNT_NR = "SET_BANK_ACCOUNT_NR";
export const SET_BANK_NAME = "SET_BANK_NAME";
export const SET_CITY = "SET_CITY";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_CONTACT_PERSON = "SET_CONTACT_PERSON";
export const SET_PRODUCT_CODE = "SET_PRODUCT_CODE";
export const SET_PRODUCT_IMG = "SET_PRODUCT_IMG";
export const SET_ORDER = "SET_ORDER";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const FETCHED_CLIENT = "FETCHED_CLIENT";
export const FETCHED_CLIENTS = "FETCHED_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const SET_LETTER = "SET_LETTER";
export const SET_USER_TO_DELETE = "SET_USER_TO_DELETE";
export const SET_DELETED_STATE = "SET_DELETED_STATE";
export const EXPORT_CLIENTS = "EXPORT_CLIENTS";
export const IMPORT_CLIENTS = "IMPORT_CLIENTS";
export const RESET_CLIENT = "RESET_CLIENT";
export const PRODUCT_LOCATION = "PRODUCT_LOCATION";
export const GET_CLIENT_PROJECTS = "GET_CLIENT_PROJECTS";
export const SAVE_CLIENT_PROJECTS = "SAVE_CLIENT_PROJECTS";

//Measures

export const FETCH_CLIENT_MEASURE = "FETCH_CLIENT_MEASURE";
export const CREATE_MEASURE = "CREATE_MEASURE";
export const GET_ASSETS = "GET_ASSETS";
export const EDIT_MEASURE = "EDIT_MEASURE";
export const CLEAR_ASSETS = "CLEAR_ASSETS";
export const CREATE_MEASURE_TEMPLATE = "CREATE_MEASURE_TEMPLATE";
export const EDIT_MEASURE_TEMPLATE = "EDIT_MEASURE_TEMPLATE";
export const GET_ALL_MEASURE_TEMPLATES = "GET_ALL_MEASURE_TEMPLATES";
export const GET_MEASURE_TEMPLATE = "GET_MEASURE_TEMPLATE";

//Offers

export const FETCH_ALL_CLIENT_OFFERS = "FETCH_ALL_CLIENT_OFFERS";
export const FETCH_CLIENT_OFFERS = "FETCH_CLIENT_OFFERS";
export const CREATE_OFFERS = "CREATE_OFFERS";
export const EDIT_OFFER = "EDIT_OFFER";
export const MODAL_CONTENT = "MODAL_CONTENT";
export const SET_NAME_SURNAME_OFFER = "SET_NAME_SURNAME_OFFER";
export const SET_DATE_OFFER = "SET_DATE_OFFER";
export const SET_FISCAL_NUMBER = "SET_FISCAL_NUMBER";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_UNIT = "SET_UNIT";
export const SET_OFFER_ID = "SET_OFFER_ID";
export const SET_PAYMENT_DEADLINE = "SET_PAYMENT_DEADLINE";
export const SET_ACCOUNT_NUMBER = "SET_ACCOUNT_NUMBER";
export const SET_DISCOUNT_VALUE = "SET_DISCOUNT_VALUE";
export const SET_DISCOUNT_TOTAL = "SET_DISCOUNT_TOTAL";
export const SET_DISCOUNT_TYPE = "SET_DISCOUNT_TYPE";
export const SET_OFFER_BASED_ON_WORKER_FIRST = "SET_OFFER_BASED_ON_WORKER_FIRST";
export const SET_OFFER_BASED_ON_WORKER_SECOND = "SET_OFFER_BASED_ON_WORKER_SECOND";
export const SET_OFFER_BASED_ON_WORKER_THIRD = "SET_OFFER_BASED_ON_WORKER_THIRD";
export const SET_TYPES = "SET_TYPES";
export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_DESIGNATION = "SET_DESIGNATION";
export const SET_PRICE = "SET_PRICE";
export const SET_PRODUCT_UNIT = "SET_PRODUCT_UNIT";
export const SET_START_DATE = "SET_START_DATE";
export const SET_TAX = "SET_TAX";
export const SET_TAX_PRICE = "SET_TAX_PRICE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_OFFER_PRODUCT = "SET_OFFER_PRODUCT";
export const SET_ALL_OFFERS = "SET_ALL_OFFERS";
export const SET_OFFER_CLIENT = "SET_OFFER_CLIENT";
export const GET_ALL_MEASURE_USERS = "GET_ALL_MEASURE_USERS";
export const RESET_OFFER_CLIENT = "RESET_OFFER_CLIENT";
export const FETCH_UNITS = "FETCH_UNITS";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const FETCH_UNITS_FOR_ARTICLE = "FETCH_UNITS_FOR_ARTICLE";
export const RESET_ASSETS = "RESET_ASSETS";
export const FETCH_ALL_OFFER_FIELDS = "FETCH_ALL_OFFER_FIELDS";
export const GET_OFFER_FIELD = "GET_OFFER_FIELD";
export const CLEAR_OFFER_FIELD = "CLEAR_OFFER_FIELD";

//Admin

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER = "FETCH_USER";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_USERS_FOR_RESPONSIBILITIES = "FETCH_USERS_FOR_RESPONSIBILITIES";
export const CREATE_UNIT = "CREATE_UNIT";
export const EDIT_UNIT = "EDIT_UNIT";
export const DELETE_UNIT = "DELETE_UNIT";
export const CREATE_ARTICLE_TYPE = "CREATE_ARTICLE_TYPE";
export const EDIT_ARTICLE_TYPE = "EDIT_ARTICLE_TYPE";
export const FETCH_ARTICLE_TYPES = "FETCH_ARTICLE_TYPES";
export const FETCH_ARTICLE_TYPE = "FETCH_ARTICLE_TYPE";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const RESET_ARTICLE = "RESET_ARTICLE";
export const GET_OFFER_CONDITIONS = "GET_OFFER_CONDITIONS"
export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS"
export const GET_COLOR_PALETTES = "GET_COLOR_PALETTES"
export const GET_SUPPLIERS = "GET_SUPPLIERS"
export const GET_IMPRESSION_QUESTIONS = "GET_IMPRESSION_QUESTIONS"
export const GET_IMPRESSION_QUESTION = "GET_IMPRESSION_QUESTIONS"


//Production

export const FETCH_RESPONSIBILITIES = "FETCH_RESPONSIBILITIES";
export const FETCH_USER_RESPONSIBILITIES = "FETCH_USER_RESPONSIBILITIES";
export const CREATE_RESPONSIBILITY = "CREATE_RESPONSIBILITY";
export const FETCH_RESPONSIBILITY = "FETCH_RESPONSIBILITY";
export const FETCH_PRODUCTION_TABLE_DATA = "FETCH_PRODUCTION_TABLE_DATA";
export const FETCH_CALENDAR_DATA = "FETCH_CALENDAR_DATA";
export const FETCH_CALENDAR_DATA_PER_CLIENT = "FETCH_CALENDAR_DATA_PER_CLIENT";
export const FETCH_PRODUCT_STATUSES = "FETCH_PRODUCT_STATUSES";
export const FETCH_PASSIVE_PRODUCTS = "FETCH_PASSIVE_PRODUCTS";
export const FETCH_WAREHOUSE = "FETCH_WAREHOUSE";
export const UPDATE_PRODUCT_DATES = "UPDATE_PRODUCT_DATES";
export const GET_MATERIALS_FOR_PRODUCTS_AND_RESPONSIBILITY = "GET_MATERIALS_FOR_PRODUCTS_AND_RESPONSIBILITY";
export const GET_MATERIAL = "GET_MATERIAL"
export const ADD_ARTICLES_TO_WAREHOUSE = "ADD_ARTICLES_TO_WAREHOUSE"
export const GET_BLUEPRINT_FILES = "GET_BLUEPRINT_FILES"
export const GET_ARCHIVED_PRODUCTS = "GET_ARCHIVED_PRODUCTS"
export const GET_CURRENT_RESPONSIBILITY_TIME = "GET_CURRENT_RESPONSIBILITY_TIME"
export const GET_ARCHIVED_MATERIALS = "GET_ARCHIVED_MATERIALS"
export const GET_ARCHIVED_RESPONSIBILITIES = "GET_ARCHIVED_RESPONSIBILITIES"
export const GET_RETURNED_RESPONSIBILITIES = "GET_RETURNED_RESPONSIBILITIES"
export const GET_RETURNED_MATERIALS = "GET_RETURNED_MATERIALS"


//Agreements

export const FETCH_ALL_CLIENT_AGREEMENTS = "FETCH_ALL_CLIENT_AGREEMENTS";
export const FETCH_CLIENT_AGREEMENT = "FETCH_CLIENT_AGREEMENT";
export const CREATE_AGREEMENTS = "CREATE_AGREEMENTS";
export const EDIT_AGREEMENT = "EDIT_AGREEMENT";
export const GET_AGREEMENT_ASSETS = "GET_AGREEMENT_ASSETS";

//Warehouse
export const FETCH_INSTALLATION_ASSIGNEES_OPTIONS = "FETCH_INSTALLATION_ASSIGNEES_OPTIONS"
export const FETCH_TRANSPORT_ASSIGNEES_OPTIONS = "FETCH_TRANSPORT_ASSIGNEES_OPTIONS";
export const FETCH_WAREHOUSE_CLIENT_INFORMATION = "FETCH_WAREHOUSE_CLIENT_INFORMATION";
export const GET_WAREHOUSE_PRODUCTS = "GET_WAREHOUSE_PRODUCTS";
export const GET_WAREHOUSE_INFORMATION = "GET_WAREHOUSE_INFORMATION"
export const GET_WAREHOUSE = "GET_WAREHOUSE"
export const GET_WAREHOUSE_RECORD = "GET_WAREHOUSE_RECORD"
export const GET_ARCHIVE_RECORDS = "GET_ARCHIVE_RECORDS";
export const GET_WAREHOUSE_LEFT_OVERS = "GET_WAREHOUSE_LEFT_OVERS";
export const GET_WAREHOUSE_INNER_STOCK = "GET_WAREHOUSE_INNER_STOCK"

//Statuses

export const MEASURE_STATUSES = [
    {
        label: 'Matje fillestare',
        value: 1
    },
    {
        label: 'Matje finale',
        value: 2
    },
    {
        label: 'Ne pritje',
        value: 3
    }
]

export const OFFER_STATUSES = [
    {
        label: "E Refuzuar",
        value: 1
    },
    {
        label: "E Aprovuar",
        value: 2
    },
    {
        label: "E derguar per konfirmim",
        value: 3
    },
    {
        label: "E padefinuar",
        value: 4
    }
]

export const AGREEMENT_STATUSES = [
    {
        value: 1,
        label: 'Ne pritje per prodhim'
    },
    {
        value: 2,
        label: 'Ne process te prodhimit'
    },
    {
        value: 3,
        label: 'Ne depo'
    },
    {
        value: 4,
        label: 'E perfunduar'
    },
    {
        value: 5,
        label: 'E anuluar'
    }
]


//Transport

export const FETCH_EQUIPMENTS = "FETCH_EQUIPMENTS";
export const FETCH_TRANSPORT_EQUIPMENTS = "FETCH_TRANSPORT_EQUIPMENTS";
export const FETCH_TRANSPORT_RECORDS = "FETCH_TRANSPORT_RECORDS"
export const FETCH_TRANSPORT_RECORD = "FETCH_TRANSPORT_RECORD"
export const FETCH_TRANSPORT_RECORD_CODE = "FETCH_TRANSPORT_RECORD_CODE"
export const FETCH_TRANSPORT_PROJECTS = "FETCH_TRANSPORT_PROJECTS"
export const GET_WAREHOUSE_RECORDS_READY_FOR_TRANSPORT = "GET_WAREHOUSE_RECORDS_READY_FOR_TRANSPORT"
export const FETCH_PROJECTS_FOR_TRANSPORT = "FETCH_PROJECTS_FOR_TRANSPORT"

//Installation

export const FETCH_INSTALLATION_RECORDS = "FETCH_INSTALLATION_RECORDS";
export const FETCH_INSTALLATION_RECORD_BY_PROJECT_ID = "FETCH_INSTALLATION_RECORD_BY_PROJECT_ID";
export const FETCH_INSTALLATION_RECORDS_FOR_MAP = "FETCH_INSTALLATION_RECORDS_FOR_MAP";
export const FETCH_PROJECTS_FOR_INSTALLATION = "FETCH_PROJECTS_FOR_INSTALLATION"

//Services

export const FETCH_SERVICE_RECORDS = "FETCH_SERVICE_RECORDS";
export const FETCH_PROJECTS_FOR_SERVICE = "FETCH_PROJECTS_FOR_SERVICE";
export const FETCH_SERVICE = "FETCH_SERVICE"
export const FETCH_PROJECTS_FOR_IMPRESSION = "FETCH_PROJECTS_FOR_IMPRESSION"

//Impression

export const FETCH_IMPRESSION_RECORDS = "FETCH_IMPRESSION_RECORDS"
export const FETCH_IMPRESSION = "FETCH_IMPRESSION"
export const FETCH_IMPRESSION_NEEDED_INFO = "FETCH_IMPRESSION_NEEDED_INFO"

export const BLUEPRINTING = "Projektimi"
export const POSTPROCESING = "Postprocesimi"

export const isMobile = window.innerWidth < 500

export const pauseReasons = [
    {
        label: 'Pauza',
        value: 5
    },
    {
        label: 'Perfundimi i orarit',
        value: 6
    },
    {
        label: 'Kalimi ne punen tjeter',
        value: 7
    },
    {
        label: 'Arsye tjeter',
        value: 8
    }
]

export const pauseReasonsEnum = {
    5: {
        label: 'Pauza',
        value: 5
    },
    6: {
        label: 'Perfundimi i orarit',
        value: 6
    },
    7: {
        label: 'Kalimi ne punen tjeter',
        value: 7
    },
    8: {
        label: 'Arsye tjeter',
        value: 8
    }
}


export const graphMonths = {
    1: {
        label: "January",
    },
    2: {
        label: "February",
    },
    3: {
        label: "March",
    },
    4: {
        label: "April",
    },
    5: {
        label: "May",
    },
    6: {
        label: "June",
    },
    7: {
        label: "July",
    },
    8: {
        label: "August",
    },
    9: {
        label: "September",
    },
    10: {
        label: "October",
    },
    11: {
        label: "November",
    },
    12: {
        label: "December",
    }
}