import * as constants from "../constants/constants";

export interface ServiceState {
    serviceRecords: any
    projectsReadyForService: any
    fetchedService: any
}

const initialState = () => {
    return {
        serviceRecords: [],
        projectsReadyForService: [],
        fetchedService: {}
    }
}

const admin = (state: ServiceState = initialState(), action: any) => {
    switch (action.type) {
        case constants.FETCH_SERVICE_RECORDS: {
            return {
                ...state,
                serviceRecords: action.payload
            };
        }
        case constants.FETCH_PROJECTS_FOR_SERVICE: {
            return {
                ...state,
                projectsReadyForService: action.payload
            };
        }
        case constants.FETCH_SERVICE: {
            return {
                ...state,
                fetchedService: action.payload
            };
        }
    }
    return state;
}

export default admin;