import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import {useHistory, withRouter} from 'react-router';
import {Input} from '../../components/Input/Input';
import {Button, EButtonType} from '../../components/Button';
import Spinner from '../../components/Spinner';
import * as adminActions from '../../actions/admin'
import * as productActions from '../../actions/production'
import {FileUpload} from '../../types/fileUpload.type';
import {checkCorrespondingInput, setOnlyNewCorrespondingItems} from './globalFunctions'
import MikaTextInput from "../../components/Input/MikaTextInput/MikaTextInput";
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import * as Yup from "yup";
import * as validationConfigs from "../../utils/validationConfigs";
import MikaPasswordInput from "../../components/Input/MikaPasswordInput/MikaPasswordInput";
import MikaFileInput from "../../components/Input/MikaFileInput/MikaFileInput";
import {useParams} from "react-router-dom";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import { ParameterTypes } from '../../types/parameter.type';
import {toast} from "react-toastify";
import {isMobile} from "../../constants/constants";
import {SelectOption} from "../../types/selectOption.type";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    loading: boolean,
    intl: any,
    production: any,
    admin: any,
    adminActions: any,
    productActions: any,
    location: any;
    buttonLoader: boolean,
    actions: any,
    departments: SelectOption[]
}

const CreateUserFrom = (props: Props) => {

    const { userId } = useParams<ParameterTypes>();

    const [responsibilityId, setResponsibilityId] = useState<number[]>([])
    const [permissionsId, setPermissionId] = useState<number[]>([])
    const [departmentId, setDepartmentId] = useState<number[]>([])
    const [adminDepartmentId, setAdminDepartmentId] = useState<number[]>([])
    const [userAvatar, setUserAvatar] = useState<FileUpload>({} as FileUpload)
    const history = useHistory();
    const [initialValues, setInitialValues] = useState({
        firstname: '',
        lastname: '',
        email: '',
        username: '',
        password: '',
        passwordConfirmation: ''
    })

    useEffect(() => {
        props.adminActions.fetchPermissions()
        props.productActions.fetchResponsibilities()
        props.actions.getAllDepartments()
    }, [])


    useEffect(() => {
        if (userId) {
            props.adminActions.fetchUser(userId).then((res) => {
                let selectedUser = res
                setInitialValues({
                    firstname: selectedUser.firstname ? selectedUser.firstname : '',
                    lastname: selectedUser.lastname ? selectedUser.lastname : '',
                    email: selectedUser.email ? selectedUser.email : '',
                    username: selectedUser.username ? selectedUser.username : '',
                    password: '',
                    passwordConfirmation: ''
                })
                if (selectedUser.permissions) {
                    let tempArray = []
                    selectedUser.permissions.forEach(permission => {
                        tempArray.push(JSON.stringify(permission?.value))
                    });
                    setPermissionId(tempArray)
                }
                if (selectedUser.responsibilities) {
                    let tempArray = []
                    selectedUser.responsibilities.forEach(responsibility => {
                        tempArray.push(JSON.stringify(responsibility?.id))
                    });
                    setResponsibilityId(tempArray)
                }
            })
        } else {
            resetFields()
        }
    }, [userId])

    function saveUser(values) {
        if(permissionsId.length == 0){
            toast.info("Ju lutem jepni leje perdoruesit")
            return
        }

        let payload = new FormData();

        payload.append("firstname", values.firstname);
        payload.append("lastname", values.lastname);
        payload.append("email", values.email);
        payload.append("username", values.username);
        payload.append("password", values.password);
        payload.append("passwordConfirmation", values.passwordConfirmation);
        if (permissionsId.length !== 0) {
            permissionsId.map((permission) => {
                payload.append("permissionsId[]", JSON.stringify(permission));
            })
        }
        if (responsibilityId.length !== 0) {
            responsibilityId.map((responsibility) => {
                payload.append("responsibilityId[]", JSON.stringify(responsibility))
            })
        }
        if (userAvatar?.file) {
            payload.append("avatar", userAvatar.file);
        }
        if (userId) {
            props.adminActions.editUser(userId, payload);
        } else {
            props.adminActions.createUser(payload).then(()=>{
                history.push('/admin');
            })
        }

    }

    function validationScheme() {
        return Yup.object().shape({
            firstname: validationConfigs.USER_FIRSTNAME_VALIDATION,
            lastname: validationConfigs.USER_LASTNAME_VALIDATION,
            email: validationConfigs.USER_EMAIL_VALIDATION,
            username: validationConfigs.USER_USERNAME_VALIDATION,
            password: validationConfigs.USER_PASSWORD_VALIDATION,
            passwordConfirmation: validationConfigs.USER_PASSWORDCONFIRM_VALIDATION
        });
    }

    function resetFields() {
        setInitialValues({
            firstname: '',
            lastname: '',
            email: '',
            username: '',
            password: '',
            passwordConfirmation: ''
        })
        setResponsibilityId([])
        setPermissionId([])
        history.push('/admin/create-user')
    }

    function createUserForm() {
        return (
            <div className="create-user-form">
                <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()}
                                   className={'w-100'}
                                   onSubmit={saveUser}>
                    <p className="w-25 d-flex small-text create-new-text">
                        <TranslatedText id="client.createUser" defaultMessage="Krijo nje perdorues"/>

                        <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                    defaultMessage="Pastro Formen"/></span>}>
                            <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                 alt="create client"/>
                        </Tooltip>
                    </p>
                    <div className="w-100 row d-flex">
                        <div className={`${isMobile ? 'col-12':'col-6'}`}>
                            <MikaTextInput name={'firstname'} bordered
                                           labelKey={translatedText({
                                               id: "user.firstName",
                                               defaultMessage: "Emri"
                                           })}/>

                            <MikaTextInput name={'lastname'} bordered
                                           labelKey={translatedText({
                                               id: "user.lastname",
                                               defaultMessage: "Mbiemri"
                                           })}/>

                            <MikaTextInput name={'email'} bordered
                                           labelKey={translatedText({
                                               id: "user.email",
                                               defaultMessage: "Email"
                                           })}/>

                        </div>
                        <div className={`${isMobile ? 'col-12':'col-6'}`}>
                            <MikaTextInput name={'username'} bordered
                                           labelKey={translatedText({
                                               id: "user.username",
                                               defaultMessage: "Username"
                                           })}/>

                            <MikaPasswordInput name="password" bordered
                                               className={'userPasswordInputs'}
                                               labelKey={translatedText({
                                                   id: "user.password",
                                                   defaultMessage: "Fjalekalimi"
                                               })}/>

                            <MikaPasswordInput name="passwordConfirmation" bordered
                                               className={'userPasswordInputs'}
                                               labelKey={translatedText({
                                                   id: "user.passwordConfirmation",
                                                   defaultMessage: "Konfirmimi i fjalkalimit"
                                               })}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${isMobile ? 'w-100':'w-50'}`}>
                            <MikaFileInput value={userAvatar}
                                className="change-avatar-input"
                                labelKey={translatedText({
                                    id: "user.userAvatar",
                                    defaultMessage: "Avatari i perdoruesit"
                                })}
                                setFiles={setUserAvatar}/>
                        </div>
                    </div>
                    <h5 className="m-0 mt-5 titles">
                        <TranslatedText id="client.permissions" defaultMessage="Lejet"/>
                    </h5>
                    <div className={`permissions-container ${isMobile ? 'user-mobile':''}`}>
                        {props.admin.permissions && props.admin.permissions.map((permission, index) => {
                            return (
                                <div className="permission" key={index}>
                                    <Input
                                        id={permission.keyLabel}
                                        name={permission?.label}
                                        value={permission?.value}
                                        type="checkbox"
                                        inputWrapperClass={`checkboxWrapper ${checkCorrespondingInput(permissionsId, permission) ? 'selected' :''}`}
                                        valueNeedeed
                                        checked={checkCorrespondingInput(permissionsId, permission)}
                                        onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setPermissionId, permissionsId)}
                                    >
                                        <p className="m-0">{permission?.label}</p>
                                    </Input>
                                </div>
                            )
                        })}
                    </div>
                    {permissionsId?.find((permissionId)=> permissionId == 7) &&
                    <>
                        <h5 className="m-0 mt-2 titles">
                            <TranslatedText id="client.responsibilities" defaultMessage="Proceset e prodhimit"/>
                        </h5>
                        <div className={`${isMobile ? 'user-mobile' : ''} responsibilities-container`}>
                            {props.production.responsibilities && props.production.responsibilities.map((responsibility, index) => {
                                return (
                                    <div className="responsibility" key={index}>
                                        <Input
                                            id={`input` + responsibility?.label}
                                            name={responsibility?.label}
                                            value={responsibility?.id}
                                            type="checkbox"
                                            inputWrapperClass={`checkboxWrapper ${checkCorrespondingInput(responsibilityId, responsibility) ? 'selected' :''}`}
                                            valueNeedeed
                                            checked={checkCorrespondingInput(responsibilityId, responsibility)}
                                            onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setResponsibilityId, responsibilityId)}
                                        >
                                            <p className="m-0">{responsibility?.label}</p>
                                        </Input>
                                    </div>
                                )
                            })}
                        </div>
                        {props?.departments?.length !== 0 && <h5 className="m-0 mt-2 titles">
                            <TranslatedText id="client.responsibilities" defaultMessage="Repartet e prodhimit"/>
                        </h5>}
                        <div className={`${isMobile ? 'user-mobile' : ''} responsibilities-container`}>
                            {props.departments && props.departments?.map((department, index) => {
                                return (
                                    <div className="responsibility" key={index}>
                                        <Input
                                            id={`input` + department?.label}
                                            name={department?.label}
                                            value={department?.value}
                                            type="checkbox"
                                            inputWrapperClass={`checkboxWrapper ${checkCorrespondingInput(responsibilityId, department) ? 'selected' :''}`}
                                            valueNeedeed
                                            checked={checkCorrespondingInput(departmentId, department)}
                                            onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setDepartmentId, departmentId)}
                                        >
                                            <p className="m-0">{department?.label}</p>
                                        </Input>
                                    </div>
                                )
                            })}
                        </div>
                        {props?.departments?.length !== 0 && <h5 className="m-0 mt-2">
                            <TranslatedText id="client.responsibilities" defaultMessage="Reparted per administrator"/>
                        </h5>}
                        <div className={`${isMobile ? 'user-mobile' : ''} responsibilities-container`}>
                            {props.departments && props.departments?.map((department, index) => {
                                return (
                                    <div className="responsibility" key={index}>
                                        <Input
                                            id={`input` + department?.label}
                                            name={department?.label}
                                            value={department?.value}
                                            type="checkbox"
                                            inputWrapperClass={`checkboxWrapper ${checkCorrespondingInput(adminDepartmentId, department) ? 'selected' :''}`}
                                            valueNeedeed
                                            checked={checkCorrespondingInput(adminDepartmentId, department)}
                                            onChange={(currentTarget) => setOnlyNewCorrespondingItems(currentTarget.checked, currentTarget.value, setAdminDepartmentId, adminDepartmentId)}
                                        >
                                            <p className="m-0">{department?.label}</p>
                                        </Input>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                    <div className="w-100 d-flex justify-content-end">
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            submit={true}
                            className={`${isMobile? 'w-100' : 'w-25'}`}
                            buttonType={EButtonType.GreyButton}>
                            <TranslatedText id="user.saveUser" defaultMessage="Ruaj perdoruesin"/>
                        </Button>
                    </div>
                </FormikFormWrapper>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> :
                createUserForm()
            }
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        admin: state.admin,
        production: state.production,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        adminActions: bindActionCreators(adminActions as any, dispatch),
        productActions: bindActionCreators(productActions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(CreateUserFrom as any)));