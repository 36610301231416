import React, {useEffect} from 'react'
import {Route, Switch, withRouter} from 'react-router'
import CreateWarehouseForm from './CreateWarehouseForm'
import WarehouseProducts from './WarehouseProducts'
import ShowAllWarehouse from './ShowAllWarehouse'
import {RootState} from "../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import * as actions from "../../actions/generalActions";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import ArchivedProducts from "../Archive/ArchivedProducts";
import Archive from "../Archive/Archive";
import Page404 from "../Page404";
import AllWarehouseProducts from "./AllWarehouseProducts";
import InnerStock from "./InnerStock";

interface Props {
    actions: any
}

const Warehouse = (props: Props) => {

    useEffect(()=>{
        props.actions.getUserPermissionsAndResponsibilities()
    }, [])

    return (
        <div className="transport inside-container">
            <Switch>
                <Route exact path="/dashboard/warehouse/clients" component={ShowAllWarehouse} />
                <Route exact path="/dashboard/warehouse/inner-stock" component={InnerStock} />
                <Route exact path="/dashboard/warehouse" component={AllWarehouseProducts} />
                <Route path="/dashboard/warehouse/create/:clientId?" component={CreateWarehouseForm}/>
                <Route path="/dashboard/warehouse/warehouseProducts" component={WarehouseProducts}/>
                <Route path="/dashboard/warehouse/archive/:moduleName?" component={Archive} />
                <Route render={()=><Page404/>}/>
            </Switch>
        </div>
    )
}
function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Warehouse as any)));