import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as serviceActions from '../../actions/service';
import * as transportActions from '../../actions/transport';
import * as installationActions from '../../actions/installation';
import "./Service.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import {Button, EButtonType} from "../../components/Button";
import {getProjectReadyForService, getService} from "../../actions/service";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import Select from "react-select";
import arrowDown from "../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg";
import DataTransferComponent from "../../components/DataTransferComponent/DataTransferComponent";
import Tooltip from "rc-tooltip";
import clearFormSvg from "../../assets/icons/archeology.svg";
import {toast} from "react-toastify";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    installation: any
    installationActions: any
    history: any
    serviceActions: any
    service: any
    buttonLoader: boolean
    permissions: any
}

const CreateServiceForm = (props: Props) => {

    const { serviceId } = useParams<ParameterTypes>();
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [problem, setProblem] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [articleForService, setArticleForService] = useState('')
    const [serialNumber, setSerialNumber] = useState('')
    const [warning, setWarning] = useState('')
    const [projects, setProjects] = useState<any>([])
    const [selectedProject, setSelectedProject] = useState<any>()

    useEffect(()=>{
        props?.serviceActions?.getProjectReadyForService()
    },[])

    useEffect(()=>{
        let tempArray = []
        props?.service?.projectsReadyForService?.forEach((project)=>{
            tempArray.push({
                label: `${project?.projectName} - ${project?.client.firstnameAndLastname} ${project?.client.lastName}`,
                value: project?.id,
                clientId: project?.client?.id,
                firstName: project?.client.firstnameAndLastname,
                lastName: project.client.lastName,
                address: project?.client?.address,
                city: project?.client?.city,
                phoneNumber: project?.client?.phoneNumber,
                email: project?.client?.email
            })
        })
        setProjects(tempArray)
    },[props?.service?.projectsReadyForService])

    useEffect(()=>{
        if(!serviceId){
            setFirstName(selectedProject?.firstName)
            setLastName(selectedProject?.lastName)
            setAddress(selectedProject?.address)
            setCity(selectedProject?.city)
            setEmail(selectedProject?.email)
            setPhoneNumber(selectedProject?.phoneNumber)
        }
    },[selectedProject])

    useEffect(()=>{
        let service = props.service?.fetchedService
        if(service){
            setSelectedProject({
                label: service?.project?.projectName,
                value: service?.project?.id
            })
            setFirstName(service?.project?.client?.firstnameAndLastname)
            setLastName(service?.project?.client?.lastName)
            setAddress(service?.project?.client?.address)
            setCity(service?.project?.client?.city)
            setEmail(service?.project?.client?.email)
            setPhoneNumber(service?.project?.client?.phoneNumber)
            setWarning(service?.warning)
            setProblem(service?.problem)
            setArticleForService(service?.articleForService)
            setSerialNumber(service?.serialNumber)
        }
    },[props?.service?.fetchedService])

    useEffect(()=>{
        if(serviceId){
            props.serviceActions.getService(serviceId)
        }else{
            resetFields()
        }
    },[serviceId])

    function resetFields() {
        setSelectedProject(null)
        setFirstName('')
        setLastName('')
        setPhoneNumber('')
        setEmail('')
        setProblem('')
        setAddress('')
        setCity('')
        setArticleForService('')
        setSerialNumber('')
        setWarning('')
        props.history.push('/dashboard/service/create')
    }

    function saveService(){
        let formData = new FormData()

        if(selectedProject?.value){
            formData.append('projectId', selectedProject?.value)
        }else{
            toast.error('Ju lutem zgjidhni projektin!')
            return
        }
        if(problem){
            formData.append('problem', problem)
        }else{
            toast.error('Komentoni ne problemin!')
            return
        }
        if(warning){
            formData.append('warning', warning);
        }
        if(articleForService){
            formData.append('articleForService', articleForService);
        }
        if(serialNumber){
            formData.append('serialNumber',serialNumber)
        }

        if(serviceId){
            props.serviceActions.editService(formData, serviceId)
        }else{
            props.serviceActions.createService(formData)
        }
    }

    function createServiceForm() {
        return (
            <div className={'w-100 mb-5 serviceContainer'}>
                <div className="d-flex mb-5">
                    <h1 className="main-title my-0">
                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                          defaultMessage="Forma për paraqitje të rastit për servis"/>
                    </h1>
                    <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                defaultMessage="Pastro Formen"/></span>}>
                        <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                             alt="create client"/>
                    </Tooltip>
                </div>
                <div className={'informationContainer'}>
                    {!serviceId && <Select
                        id={`projectMultiSelect`}
                        name="projectMultiSelect"
                        options={projects}
                        closeMenuOnSelect={false}
                        className={'assigneeSelect'}
                        classNamePrefix="react-select"
                        value={selectedProject || ''}
                        onChange={(value) => setSelectedProject(value)}
                        placeholder={translatedText({
                            id: "client.order",
                            defaultMessage: "Projektet qe doni ti zgjidhni per instalim"
                        })}/>}
                </div>
                <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
                    <div className={`${isMobile ? 'w-100': 'w-50'} d-flex justify-content-start`}>
                        <div className={`${isMobile ? 'w-100': 'w-95'}`}>
                            <div className={'d-flex'}>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={`${isMobile? 'w-100' :'w-50'} pr-15 textarea-wrapper`}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={firstName || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="EMRI"/></p>
                                </Input>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={`${isMobile? 'w-100' :'w-50'} pr-15 textarea-wrapper`}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={lastName || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="MBIEMRI"/></p>
                                </Input>
                            </div>
                            <div className={'d-flex'}>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={phoneNumber || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="NR. TELEFONIT"/></p>
                                </Input>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={email || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="EMAIL"/></p>
                                </Input>
                            </div>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper"}
                                type="textarea"
                                inputTextType={EInputTextType.SimpleInput}
                                value={problem || ''}
                                onChange={(name, value) => setProblem(value)}
                            >
                                <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="PROBLEMI"/></p>
                            </Input>
                        </div>
                    </div>
                    <div className={`${isMobile ? 'w-100': 'w-50'} d-flex justify-content-end`}>
                        <div className={`${isMobile ? 'w-100': 'w-95'}`}>
                            <div className={'d-flex'}>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={address || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="ADRESSA"/></p>
                                </Input>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={city || ''}
                                    disabled
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="QYTETI"/></p>
                                </Input>
                            </div>
                            <div className={'d-flex'}>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={articleForService || ''}
                                    onChange={(name, value) => setArticleForService(value)}
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="ARTIKULLI PER SERVISIM"/></p>
                                </Input>
                                <Input
                                    name="comments"
                                    label="label"
                                    id="comments"
                                    defaultValue=''
                                    inputWrapperClass={"w-50 pr-15 textarea-wrapper"}
                                    type="text"
                                    inputTextType={EInputTextType.SimpleInput}
                                    value={serialNumber || ''}
                                    onChange={(name, value) => setSerialNumber(value)}
                                >
                                    <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="NR. SERIK"/></p>
                                </Input>
                            </div>
                            <Input
                                name="comments"
                                label="label"
                                id="comments"
                                defaultValue=''
                                inputWrapperClass={"w-100 textarea-wrapper"}
                                type="textarea"
                                inputTextType={EInputTextType.SimpleInput}
                                value={warning || ''}
                                onChange={(name, value) => setWarning(value)}
                            >
                                <p className={'m-0 font-weight-bold'}><TranslatedText id="app.agreements.appendFinalAgreement" defaultMessage="VEREJTJA"/></p>
                            </Input>
                        </div>
                    </div>
                </div>
                <div className={'w-100 d-flex justify-content-end'}>
                    {((!props.permissions.observer) || props.permissions.fullPrivileges) &&<Button
                        isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                        onClick={saveService}
                        className="my-5 w-50"
                        buttonType={EButtonType.PurpleButton}>
                        <TranslatedText id="client.editContact"
                                        defaultMessage="Ruaj"/>
                    </Button>}
                </div>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> : createServiceForm()}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        service: state.service,
        installation: state.installation,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        serviceActions: bindActionCreators(serviceActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
        installationActions: bindActionCreators(installationActions as any, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateServiceForm as any)))