import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import * as transportActions from '../../actions/transport';
import "./Transport.scss";
import {withRouter} from 'react-router';
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import {SelectOption} from '../../types/selectOption.type';
import {EInputTextType, Input} from "../../components/Input/Input";
import Select from "react-select";
import {Button, EButtonType} from "../../components/Button";
import {toast} from "react-toastify";
import Tooltip from "rc-tooltip";
import calendarIcon from "../../assets/icons/blackCalendar.svg";
import moment from "moment";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L, {LatLngExpression} from "leaflet";
import FontAwesome from 'react-fontawesome';
import TranslatedText, {translatedText} from "../../components/TranslatedText";

interface Props {
    intl: any;
    setLoading: boolean;
    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    validationMessageConfig: any;
    letter: string;
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    transport: any;
    transportActions: any;
    archiveRecords: any;
    transportCode: string
    history: any
    buttonLoader: boolean
    permissions: any
}

const CreateContactForm = (props: Props) => {

    const [position, setPosition] = useState<LatLngExpression>()
    const [projects, setProjects] = useState<any>()
    const [neededEquipments, setNeededEquipments] = useState<any>()
    const [selectedProjects, setSelectedProjects] = useState<any>([])
    const [checkedEquipments, setCheckedEquipments] = useState<any>()
    const [allEquipmentsTaken, setAllEquipmentsTaken] = useState<any>()
    const [transportRecords, setTransportRecords] = useState<any[]>()
    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        if (props.transportCode) {
            props?.transportActions?.getTransportRecordsByCode(props.transportCode)
        } else {
            resetFields()
        }
        getCurrentPosition()
        props.transportActions?.fetchTransportProjects()
        props.transportActions?.getWarehouseRecordsReadyForTransport()
        props.actions.getArchiveRecords(`archive/warehouse`)
    }, [])

    useEffect(() => {
        let tempArray = []
        props.transport?.warehouseRecordsReadyForTransport?.forEach((record) => {
            if (record?.project[0]) {
                tempArray.push({
                    value: record?.project[0]?.id,
                    label: record?.project[0]?.projectName,
                    location: record?.project[0]?.projectLocation ? record?.project[0]?.projectLocation?.split(',') : '',
                    products: record?.productWarehouseRecord,
                    warehouseRecordFile: record?.invoiceWithUrl,
                    warehouseRecordId: record?.id
                })
            }
        })
        setProjects(tempArray)
    }, [props.transport?.warehouseRecordsReadyForTransport])

    useEffect(() => {
        if (props.transport.transportRecordsByCode && props.transportCode) {
            let tempArray = []
            props.transport.transportRecordsByCode?.forEach((transportRecord) => {
                let projectLocation = transportRecord?.project?.projectLocation?.split(',')
                tempArray.push({
                    id: transportRecord?.id,
                    value: transportRecord?.project?.value,
                    label: transportRecord?.project?.label,
                    location: projectLocation?.length === 2 ? projectLocation : [],
                    transportDetails: transportRecord?.transportDetails,
                    comment: transportRecord?.comment,
                    orderAcceptanceDate: transportRecord?.orderAcceptanceDate || '',
                    orderArrivalDate: transportRecord?.orderArrivalDate || '',
                    warehouseRecordFile: transportRecord?.warehouse?.invoiceWithUrl,
                    warehouseRecordId: transportRecord?.warehouse?.id
                })
            })
            setSelectedProjects(tempArray)
        } else {
            resetFields()
        }
    }, [props.transport.transportRecordsByCode])


    useEffect(() => {
        if (selectedProjects.length !== 0) {
            let formData = new FormData()

            selectedProjects?.forEach((project) => {
                formData.append('projectId[]', project.value)
            })
            props?.transportActions?.fetchTransportEquipments(formData)
        } else {
            setNeededEquipments([])
        }

        let tempArray = []
        selectedProjects?.forEach((project) => {
            tempArray.push({
                ...project,
                orderAcceptanceDate: moment().format('YYYY-MM-DD')
            })
        })
        setTransportRecords(tempArray)
    }, [selectedProjects])

    useEffect(() => {
        setNeededEquipments(props?.transport?.transportEquipments)
    }, [props?.transport?.transportEquipments])

    useEffect(() => {
        if (neededEquipments) {
            let tempArray = []
            neededEquipments?.forEach(() => {
                tempArray.push(props?.transportCode ? true : false)
            })
            setAllEquipmentsTaken(props?.transportCode ? true : false)
            setCheckedEquipments(tempArray)
        } else {
            setCheckedEquipments([])
        }
    }, [neededEquipments])

    // function getCurrentPosition() {
    //     navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
    //         if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
    //             setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
    //         }
    //     });
    // }
    function getCurrentPosition() {
        navigator.geolocation.getCurrentPosition(function (geoLocationPosition) {
            if (geoLocationPosition?.coords?.latitude && geoLocationPosition?.coords?.longitude) {
                setPosition([geoLocationPosition.coords.latitude, geoLocationPosition.coords.longitude])
            }
        },(err)=> {
            console.warn(`ERROR(${err.code}): ${err.message}`)
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 60000,
        });
    }

    function resetFields() {
        setSelectedProjects([])
        props.history.push('/dashboard/transport/create')
    }

    function clearDate(which, index) {
        let tempArray = [
            ...transportRecords
        ]
        tempArray[index] = {
            ...tempArray[index],
            [which]: ''
        }
        setTransportRecords(tempArray)
    }

    function getCurrentDate(which, index) {
        let tempArray = [
            ...transportRecords
        ]
        let currentDate = moment().format("YYYY-MM-DD")
        tempArray[index] = {
            ...tempArray[index],
            [which]: currentDate
        }
        setTransportRecords(tempArray)
    }

    function toggleAllEquipments(checked) {
        let tempArray = []
        checkedEquipments && checkedEquipments.forEach(() => {
            tempArray.push(checked)
        })
        setAllEquipmentsTaken(checked)
        setCheckedEquipments(tempArray)
    }

    function saveCheckedEquipments(checked, value, index) {
        let tempArray = [...checkedEquipments]
        tempArray[index] = checked
        setCheckedEquipments(tempArray)
    }

    function saveTransport() {

        let anyCreationsLeft = false

        transportRecords?.forEach(record => {
            if (!record.id) {
                anyCreationsLeft = true
            }
        })

        if (anyCreationsLeft) {
            if (selectedProjects.length === 0) {
                toast.error('Zgjidhni projektet per transport!')
                return
            }
            let valid = true
            if (neededEquipments.length !== 0) {
                checkedEquipments?.forEach((check) => {
                    if (!check) {
                        valid = false
                    }
                })
            }

            if (!valid) {
                toast.error('Duhet te merrni te gjitha pajisjet e kerkuara!')
                return;
            }
            let index = 0
            let formData = new FormData()
            transportRecords?.forEach((record) => {
                if (!record?.id) {
                    formData.append(`projectId[${index}]`, record?.value)
                    formData.append(`warehouseRecordId[${index}]`, record?.warehouseRecordId)
                    if (record?.transportDetails) {
                        formData.append(`transportDetails[${index}]`, record?.transportDetails)
                    }
                    if (record?.orderArrivalDate) {
                        formData.append(`arrivalDate[${index}]`, record?.orderArrivalDate)
                    }
                    if (record?.orderAcceptanceDate) {
                        formData.append(`acceptanceDate[${index}]`, record?.orderAcceptanceDate)
                    }
                    if (record?.comment) {
                        formData.append(`comment[${index}]`, record?.comment)
                    }
                    if (props?.transportCode) {
                        formData.append(`code[${index}]`, props?.transportCode);
                    }
                    index += 1
                }
            })
            props.transportActions.createTransportRecord(formData).then(() => {
                toast.success('Rekordi u krijua me sukses!')
                props.history.push('/dashboard/transport')
            })
        } else {
            toast.error('Nuk keni projekte te reja per te ruajtur!')
        }
    }

    function setAllTransportRecords(name, value, index) {
        let tempArray = [
            ...transportRecords
        ]

        tempArray[index] = {
            ...tempArray[index],
            [name]: value
        }

        setTransportRecords(tempArray)
    }

    function editThisTransportRecord(record) {
        if (!record?.orderAcceptanceDate) {
            toast.error('Duhet te kete date te nisjes!')
            return
        }
        let formData = new FormData()
        if (record?.warehouseRecordId) {
            formData.append(`warehouseRecordId`, record?.warehouseRecordId)
        }
        if (record?.transportDetails) {
            formData.append(`transportDetails`, record?.transportDetails)
        }
        if (record?.orderArrivalDate) {
            formData.append(`arrivalDate`, record?.orderArrivalDate)
        }
        if (record?.orderAcceptanceDate) {
            formData.append(`acceptanceDate`, record?.orderAcceptanceDate)
        }
        if (record?.comment) {
            formData.append(`comment`, record?.comment)
        }

        props.transportActions.editTransportRecord(formData, record.id).then(() => {
            toast.success('Transporti u editua me sukses!')
        })
    }

    function toggleMap() {
        setShowMap(!showMap)
    }

    function createTransportForm() {
        return (
            <div className={'w-100 mb-5 transportContainer'}>
                <div className={'formWrapper'}>
                    <div className={'projectMultiSelectContainer'}>
                        <Select
                            id={`projectMultiSelect`}
                            name="projectMultiSelect"
                            options={projects}
                            closeMenuOnSelect={false}
                            className={'assigneeSelect'}
                            classNamePrefix="react-select"
                            isMulti
                            value={selectedProjects || ''}
                            onChange={(value) => setSelectedProjects(value)}
                            placeholder={translatedText({
                                id: "client.order",
                                defaultMessage: "Projektet qe doni ti zgjidhni per transport"
                            })}/>
                    </div>
                    <div className={'transportFormContainer d-flex justify-content-between'}>
                        <div className="transportFormContainer-left">
                            {transportRecords && transportRecords.map((project, index) => {
                                return <div
                                    className={`projectFormForTransport ${transportRecords[index]?.orderAcceptanceDate && transportRecords[index]?.orderArrivalDate ? 'alert alert-success' : ''}`}
                                    key={index}>
                                    <div className={'d-flex'}>
                                        <h3 className={'w-75'}>{project?.label}{transportRecords[index]?.orderAcceptanceDate && transportRecords[index]?.orderArrivalDate &&
                                        <Tooltip placement="right"
                                                 overlay={<span><TranslatedText id="app.clearForm"
                                                                                  defaultMessage="Transporti u krye me sukses"/></span>}>
                                            <FontAwesome name={'check'}/>
                                        </Tooltip>}</h3>
                                    </div>
                                    <div className={'fileContainer'}>
                                        {project?.warehouseRecordFile &&
                                        <a target={'_blank'} href={project?.warehouseRecordFile}>
                                            <div className={'fileIcon'}>
                                                <Tooltip placement="right"
                                                         overlay={<span><TranslatedText id="app.clearForm"
                                                                                          defaultMessage="Fletdergesa"/></span>}>
                                                    <FontAwesome name="file"/>
                                                </Tooltip>
                                            </div>
                                        </a>}
                                        {project?.location && <Tooltip placement="right"
                                                                       overlay={<TranslatedText id="app.clearForm"
                                                                                                  defaultMessage="Linku per ne harte"/>}>
                                            <a target={'_blank'}
                                               href={`https://www.google.com/maps/?q=${project?.location[0]},${project?.location[1]}`} className={'mapIcon'}><FontAwesome name={'map'}/></a>
                                        </Tooltip>}
                                    </div>
                                    <Input
                                        name="orderAcceptanceDate"
                                        label="label"
                                        id="orderAcceptanceDate"
                                        inputWrapperClass={"w-100"}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={transportRecords[index]?.orderAcceptanceDate ? moment(transportRecords[index]?.orderAcceptanceDate).format('DD-MM-YYYY') : ''}
                                        disabled
                                    >
                                        <p className={'m-0'}>Data e fillimit te transportit</p>
                                        <div className={'clearDate'}>
                                            <p className={'x-sign'}
                                               onClick={() => clearDate('orderAcceptanceDate', index)}>+</p>
                                        </div>
                                        {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                                  overlay={<span>Ruaj daten</span>}>
                                            <img src={calendarIcon} alt=""
                                                 className="pinLogoForMeasure"
                                                 onClick={() => getCurrentDate('orderAcceptanceDate', index)}/>
                                        </Tooltip>}
                                    </Input>
                                    <Input
                                        name="orderAcceptationDate"
                                        label="label"
                                        id="orderAcceptationDate"
                                        inputWrapperClass={"w-100"}
                                        type="text"
                                        inputTextType={EInputTextType.SimpleInput}
                                        value={transportRecords[index]?.orderArrivalDate ? moment(transportRecords[index]?.orderArrivalDate).format('DD-MM-YYYY') : ''}
                                        disabled
                                    >
                                        <p className={'m-0'}>Data e arritjes te transportit</p>
                                        <div className={'clearDate'}>
                                            <p className={'x-sign'}
                                               onClick={() => clearDate('orderArrivalDate', index)}>+</p>
                                        </div>
                                        {((!props.permissions.observer) || props.permissions.fullPrivileges) && <Tooltip placement="right"
                                                  overlay={<span>Ruaj daten</span>}>
                                            <img src={calendarIcon} alt=""
                                                 className="pinLogoForMeasure"
                                                 onClick={() => getCurrentDate('orderArrivalDate', index)}/>
                                        </Tooltip>}
                                    </Input>
                                    <Input
                                        name="transportDetails"
                                        label="label"
                                        id="transportDetails"
                                        inputWrapperClass={"w-100"}
                                        type="textarea"
                                        value={transportRecords[index] ? transportRecords[index]?.transportDetails : ''}
                                        onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                    >
                                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                                          defaultMessage="Detajet e transportit"/>
                                    </Input>
                                    {transportRecords[index] && transportRecords[index].id && <Input
                                        name="comment"
                                        label="label"
                                        id="comment"
                                        inputWrapperClass={"w-100"}
                                        type="textarea"
                                        value={transportRecords[index] ? transportRecords[index]?.comment : ''}
                                        onChange={(name, value) => setAllTransportRecords(name, value, index)}
                                    >
                                        <TranslatedText id="app.agreements.appendFinalAgreement"
                                                          defaultMessage="Verejtja nga procesi i matjes"/>
                                    </Input>}
                                    {transportRecords[index] && transportRecords[index].id &&
                                    <div className={'d-flex justify-content-end'}>
                                        <Button
                                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                                            onClick={() => editThisTransportRecord(transportRecords[index])}
                                            className="m-0 p-2"
                                            buttonType={EButtonType.PurpleButton}>
                                            <TranslatedText id="client.editContact"
                                                              defaultMessage="Ruaj"/>
                                        </Button>
                                    </div>}
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between">
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            className={'w-25'}
                            onClick={toggleMap}
                            buttonType={EButtonType.PurpleButton}>
                            {showMap ? <TranslatedText id="client.saveContact" defaultMessage="Mbyll harten"/> :
                                <TranslatedText id="client.saveContact" defaultMessage="Hap harten"/>}
                        </Button>
                        <Button
                            isLoading={props?.buttonLoader}
                        isDisabled={props?.buttonLoader}
                            className={'w-50'}
                            onClick={saveTransport}
                            buttonType={EButtonType.PurpleButton}>
                            <TranslatedText id="client.saveContact" defaultMessage="Ruaj"/>
                        </Button>
                    </div>
                </div>
                {selectedProjects.length !== 0 &&
                <div className="transportFormContainer-right d-flex justify-content-end">
                    <div className="equipmentsCheckboxContainer">
                        <div className="transportEquipmentListHeader w-100">
                            <div className="titleContainer w-100">
                                <p>Lista e pajisjeve te nevojitura</p>
                            </div>
                        </div>
                        {neededEquipments?.map((equipment, index) => {
                            return (
                                <div key={index} className="transportEquipmentListContent w-100 d-flex">
                                    <div className="w-100 d-flex justify-content-between">
                                        <p>{equipment?.label}</p>
                                        <Input
                                            value={equipment?.value}
                                            type="checkbox"
                                            valueNeedeed
                                            checked={checkedEquipments[index]}
                                            onChange={(currentTarget) => saveCheckedEquipments(currentTarget.checked, currentTarget.value, index)}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <p className={'m-0'}>I kam te gjitha</p>
                            <Input
                                value={'checkAll'}
                                type="checkbox"
                                inputWrapperClass={'m-0 d-flex align-items-center'}
                                valueNeedeed
                                checked={allEquipmentsTaken}
                                onChange={(currentTarget) => toggleAllEquipments(currentTarget.checked)}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    let icon = L.icon({
        iconUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=',
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41]
    })

    return (
        <div className="wrapper w-100">
            {props.loading ? <Spinner/> : createTransportForm()}
            {showMap && <div className={'mapAndEquipmentsContainer'}>
                {position && <div className="mapContainer mobile">
                    <MapContainer
                        center={position}
                        zoom={13}
                        scrollWheelZoom={false}
                        className={'map'}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {selectedProjects && selectedProjects?.map((project) => {
                            return (<>
                                {project?.location?.length === 2 && <Marker
                                    position={project?.location}
                                    icon={icon}
                                >
                                    <Popup>
                                        <p className={'mb-0'}>{project?.label}</p>
                                        {project?.location[0] && <a target={'_blank'}
                                                                    href={`https://www.google.com/maps/?q=${project?.location[0]},${project?.location[1]}`}
                                                                    className={'mb-0 googleMapsLink'}>Shiko ne Google
                                            maps</a>}
                                    </Popup>
                                </Marker>}
                            </>)
                        })}
                    </MapContainer>
                </div>}
            </div>}
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
        transport: state.transport
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        transportActions: bindActionCreators(transportActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateContactForm as any)))