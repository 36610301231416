import React, { useEffect, useState } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as offerActions from '../../actions/offers';
import * as clientActions from '../../actions/client';
import * as measuresActions from '../../actions/measures';
import { withRouter } from 'react-router';
import './Offers.scss'
import { Client, ClientObject } from '../../types/client.type';
import TableBbros from "../../components/Table/TableBbros";
import {
    COLUMNS,
    COLUMNS_MOBILE,
    OFFER_COLUMNS,
    OFFER_COLUMNS_MOBILE,
    OPTIONS
} from "../../components/Table/TableConstants";
import EditIcon from "@material-ui/icons/Edit";
import MikaSelect from "../../components/BbrosSelect/mikaSelect.component";
import { SelectOption } from "../../types/selectOption.type";
import {MEASURE_STATUSES, OFFER_STATUSES} from "../../constants/constants";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DeleteModalComponent from "../../components/Modal/DeleteModal";
import ModalComponent from "../../components/Modal/ModalComponent";
import {translatedText} from "../../components/TranslatedText";
import {useParams} from "react-router-dom";
import {ParameterTypes} from "../../types/parameter.type";
import {getClientsForOffer} from "../../actions/generalActions";
import {toast} from "react-toastify";
import moment from "moment";
import DifferentColoredPlus from "../../assets/icons/differentColoredPlus.svg";
import {Button, EButtonType} from "../../components/Button";
import FontAwesome from 'react-fontawesome';


interface Props {
    measuresActions: any;
    intl: any;
    loading: boolean;
    setLoading: boolean;
    history: any;
    client: any;
    clientActions: any;
    offerActions: any;
    actions: any,
    offers: any,
    measures: any,
    measureActions: any,
    letter: string;
    clientsByModuleData: Array<Client>;
    buttonLoader: boolean
}
const Offers = (props: Props) => {
    const {statusId} = useParams <ParameterTypes>()
    const [selectedStatus, setSelectedStatus] = useState<SelectOption>({} as SelectOption)
    const [startDate, setStartDate] = useState<any>()
    const [endDate, setEndDate] = useState<any>()
    const [filtersOpened, setFiltersOpened] = useState<boolean>(false)

    useEffect(() => {
        getClients()
    }, [statusId])

    function filterData(){
        if(selectedStatus?.value || (startDate && endDate)){
            getClients()
            setFiltersOpened(false)
        }else{
            if(!selectedStatus?.value){
                getClients()
                setFiltersOpened(false)
                return
            }
            toast.info('Duhet te mbushni dy datat!')
        }
    }

    function getClients(){
        props?.actions?.getClientsForOffer(statusId || selectedStatus?.value, startDate, endDate)
    }

    function editOfferData(client: any) {
        props.history.push({ pathname: `/dashboard/offers/create/${client?.id}/${client?.projectId}`})
    }

    const actions = [
        {
            icon: EditIcon,
            tooltip: 'Edit',
            onClick: (event: Event, rowData: ClientObject) => editOfferData(rowData)
        }
    ]

    return (
        <div className="wrapper allModuleContent">
            <div className={`productionFilterButton`}>
                <div className={'w-100 h-100 position-relative'}>
                    <div className={'filterIconContainer'} onClick={() => setFiltersOpened(!filtersOpened)}>
                        <FontAwesome name={'filter'}/>
                    </div>
                    <div className={`filterDropdownContainer ${filtersOpened ? 'show' : 'hide'}`}>
                        <div className={'filterWrapper'}>
                            <div className={'closeFiltersButton'} onClick={() => setFiltersOpened(!filtersOpened)}>
                                <FontAwesome name={'close'}/>
                            </div>
                            {!statusId && <MikaSelect options={OFFER_STATUSES} placeholder={'Statusi'} value={selectedStatus}
                                                      setEntireOptionFunction={setSelectedStatus}/>}
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e fillimit</p>
                                <p className={'dateValue'}>{startDate ? moment(startDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {startDate && <img src={DifferentColoredPlus} onClick={() => setStartDate('')}
                                                   className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={startDate}
                                           onChange={(e) => setStartDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`dateContainer`}>
                                <p className={'label'}>Data e mbarimit</p>
                                <p className={'dateValue'}>{endDate ? moment(endDate)?.format('DD-MM-YYYY') : 'DD-MM-YYYY'}</p>
                                {endDate &&
                                <img src={DifferentColoredPlus} onClick={() => setEndDate('')} className="x-signDate"/>}
                                <div className={'calendar'}>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e?.target?.value)}/>
                                    <div className={'calendarIcon'}>
                                        <FontAwesome
                                            name="calendar"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                isLoading={props?.buttonLoader}
                                isDisabled={props?.buttonLoader}
                                className="m-auto w-100"
                                onClick={filterData}
                                buttonType={EButtonType.GreyButton}>
                                Filtro
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <TableBbros
                className={'firstColumnThinner'}
                title={translatedText({ id: "table.offers", defaultMessage: "Ofertat" })}
                data={props?.clientsByModuleData}
                columns={window.innerWidth > 500 ? OFFER_COLUMNS : OFFER_COLUMNS_MOBILE}
                options={OPTIONS}
                actions={actions}
                isLoading={props.loading}
            />
        </div>
    )
}

function mapGlobalStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        measures: state.measures,
        offers: state.offers
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        offerActions: bindActionCreators(offerActions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        measuresActions: bindActionCreators(measuresActions as any, dispatch),
        actions: bindActionCreators(actions as any, dispatch),
    }
}

export default withRouter(connect(mapGlobalStateToProps, mapDispatchToProps)(injectIntl(Offers as any)));
