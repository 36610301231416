import React, {useEffect, useState} from 'react';
import {Dispatch, bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {RootState} from '../../reducers';
import * as actions from '../../actions/generalActions';
import * as clientActions from '../../actions/client';
import {resetClient} from "../../actions/client";
import * as generalActions from '../../actions/generalActions';
import {EInputTextType, Input} from '../../components/Input/Input';
import clearFormSvg from '../../assets/icons/archeology.svg'
import {Button, EButtonType} from '../../components/Button';
import {toast} from 'react-toastify';
import "./Contact.scss";
import moment from 'moment';
import {useHistory, withRouter} from 'react-router';
import * as Yup from "yup";
import * as validationConfigs from '../../utils/validationConfigs';
import Tooltip from "rc-tooltip";
import {User} from '../../types/user.type';
import Spinner from '../../components/Spinner';
import {apiCaller, axiosCaller} from '../../utils/apiCaller';
import {ArticleInfomation} from '../../types/ArticleInformation.type';
import MikaSelect from '../../components/BbrosSelect/mikaSelect.component';
import {SelectOption} from '../../types/selectOption.type';
import MikaTextInput from "../../components/Input/MikaTextInput/MikaTextInput";
import FormikFormWrapper from "../../components/FormikFormWrapper/FormikFormWrapper";
import FontAwesome from 'react-fontawesome'
import {useParams} from "react-router-dom";
import {formatDate} from '../../utils/variableEvaluation';
import MikaNumberInput from '../../components/Input/MikaNumberInput/MikaNumberInput';
import {ParameterTypes} from '../../types/parameter.type';
import {checkIfUserIsAuthorized} from "../Admin/globalFunctions";
import CreateProjectModal from "./CreateProjectModal";
import {isMobile} from "../../constants/constants";
import TranslatedText, {translatedText} from "../../components/TranslatedText";
import {SECOND_PHONE_VALIDATION} from "../../utils/validationConfigs";

interface Props {
    intl: any;
    setLoading: boolean;

    nextStep(): void;

    actions: any;
    clientActions: any;
    client: any;
    loading: boolean;
    articles: ArticleInfomation[];
    validationMessageConfig: any;
    letter: string;
    app: any;
    user: any;
    measureUsers: User[];
    generalActions: any;
    allClients: SelectOption[];
    countries: any[];
    citiesFields: any[];
    permissions: any;
    history: any;
    buttonLoader: boolean
}

const CreateContactForm = (props: Props) => {

    const language:any = TranslatedText

    const {clientId} = useParams<ParameterTypes>();
    const [dateRange, setDateRange] = useState([new Date(Date.now() - 24 * 24 * 60 * 60 * 1000), new Date()]);
    const [startDate, endDate] = dateRange;
    const [createAt, setCreatedAt] = useState()
    const [searchedClient, setSearchedClient] = useState<string>()
    const [clients, setClients] = useState<SelectOption[]>([])
    const [countries, setCountries] = useState<SelectOption[]>([])
    const [country, setCountry] = useState<SelectOption>({} as SelectOption)
    const [cities, setCities] = useState<SelectOption[]>([])
    const [city, setCity] = useState<SelectOption>({} as SelectOption)
    const [filteredClients, setFilteredClients] = useState<SelectOption[]>([])
    const [showRequiredMessage, setShowRequiredMessage] = useState<boolean>(false)
    const [isProjectModalOpened, setIsProjectModalOpened] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState({
        nameAndSurname: '',
        lastName: '',
        phoneNr: '',
        secondPhoneNr: '',
        email: '',
        comment: '',
        businessNr: '',
        address: '',
        secondAddress: '',
        bankAccountNr: '',
        bankName: '',
        contactPerson: '',
        file: null
    })
    const fromDate = moment().subtract(24, 'd').format('DD.MM.YYYY') || "24.06.2021"
    const toDate = moment(endDate).format("DD.MM.YYYY")
    const letter_ = props.letter || "A";

    const history = useHistory();

    useEffect(() => {
        props.generalActions.fetchArticles();
        props.actions.getAllMeasureUsers();
        props.actions.fetchCountries();
        axiosCaller('GET', `clients/all-clients-with-id-and-firstname-and-lastname`).then(res => {
            setClients(res.data.data)
        })
    }, []);

    useEffect(() => {
        if (Object.keys(props.permissions)?.length !== 0 && !checkIfUserIsAuthorized(props.permissions, props.permissions.fullPrivileges, props.permissions.contact)) {
            toast.error(translatedText({
                id: "app.yourNotAuthenticatedToModifyDataForThisModule",
                defaultMessage: "Nuk keni qasje ne modifikimin e te dhenave te ketij moduli!"
            }))
            history.push('/dashboard/contact')
        }
    }, [props.permissions])

    useEffect(() => {
        getModuleData()
    }, [clientId])

    useEffect(() => {
        let tempArrayOfClients = clients

        tempArrayOfClients = tempArrayOfClients.filter(val => val.firstNameAndLastName.includes(searchedClient?.charAt(0).toUpperCase()))

        setFilteredClients(tempArrayOfClients)

    }, [searchedClient])

    useEffect(() => {
        let tempCountriesArray = []
        props.countries.forEach((countryObject, index) => {
            tempCountriesArray.push({
                label: countryObject.country,
                value: index
            })
        })
        setCountries(tempCountriesArray)
    }, [props.countries])

    useEffect(() => {
        if (country.label) {
            props.actions.fetchCities(country.label)
        }
        if (props.client?.client?.country == country?.label) {
            if (props.client?.client?.city) {
                setCity({
                    label: props.client?.client?.city,
                    value: undefined
                })
                return
            }
        }
        setCity({
            label: '',
            value: undefined
        })
    }, [country])

    useEffect(() => {
        let tempCitiesArray = []
        props.citiesFields.forEach((cityObject, index) => {
            tempCitiesArray.push({
                label: cityObject.city,
                value: index
            })
        })
        setCities(tempCitiesArray)

    }, [props.citiesFields])

    function getModuleData() {
        if (clientId !== undefined) {
            props.clientActions.getClientProjects(clientId)
            props.clientActions.fetchClient(clientId, 1).then((res) => {
                setInitialValues({
                    nameAndSurname: res.data?.firstnameAndLastname ? res.data?.firstnameAndLastname : '',
                    lastName: res.data?.lastName ? res.data?.lastName : '',
                    phoneNr: res.data?.phoneNumber ? res.data?.phoneNumber : '',
                    secondPhoneNr: res.data?.secondPhoneNumber ? res.data?.secondPhoneNumber : '',
                    email: res.data?.email ? res.data?.email : '',
                    comment: res.data?.commentAndDetails ? res.data?.commentAndDetails : '',
                    businessNr: res.data?.businessNumber ? res.data?.businessNumber : '',
                    address: res.data?.address ? res.data?.address : '',
                    secondAddress: res.data?.secondAddress ? res.data?.secondAddress : '',
                    bankAccountNr: res.data?.bankAccountNumber ? res.data?.bankAccountNumber : '',
                    bankName: res.data?.bankAccount ? res.data?.bankAccount : '',
                    contactPerson: res.data?.contactPerson ? res.data?.contactPerson : '',
                    file: {
                        employerId: "",
                        fileName: "",
                        file: null
                    }
                })

                setCountry({
                    label: res.data?.country,
                    value: undefined
                })
                setCreatedAt(res.data?.createdAt)
            })
        } else {
            resetFields()
        }
    }

    function validationScheme() {
        return Yup.object().shape({
            nameAndSurname: validationConfigs.NAME_SURNAME_VALIDATION,
            lastName: validationConfigs.LAST_NAME_VALIDATION,
            phoneNr: validationConfigs.PHONE_VALIDATION,
            secondPhoneNr: validationConfigs.SECOND_PHONE_VALIDATION,
            businessNr: validationConfigs.BUSINESS_NR_VALIDATION,
            address: validationConfigs.ADDRESS_VALIDATION,
            email: validationConfigs.EMAIL_VALIDATION,
            bankAccountNr: validationConfigs.BANK_ACCOUNT_NR_VALIDATION,
            comment: validationConfigs.COMMENT_VALIDATION,
            bankName: validationConfigs.BANK_NAME_VALIDATION,
            contactPerson: validationConfigs.CONTACT_PERSON_VALIDATION,
            order: validationConfigs.ORDER_VALIDATION,
        });
    }

    function resetFields() {
        props.clientActions.saveClientProjects([])
        resetClient({
            id: undefined
        })
        setInitialValues({
            nameAndSurname: '',
            lastName: '',
            phoneNr: '',
            secondPhoneNr: '',
            email: '',
            comment: '',
            businessNr: '',
            address: '',
            secondAddress: '',
            bankAccountNr: '',
            bankName: '',
            contactPerson: '',
            file: null
        })
        setCountry({label: 'Kosovo', value: 76})
        setCreatedAt(null)
        history.push('/dashboard/contact/create')
    }

    const saveClient = (values) => {
        if (props.client?.clientProjects?.length === 0) {
            toast.error("Ju lutem krijo projekt per kete klient")
            return;
        }
        if (!city.label) {
            setShowRequiredMessage(true)
            return
        }
        if (!country.label) {
            setShowRequiredMessage(true)
            return
        }
        let formData = new FormData();

        if (values.nameAndSurname) {
            formData.append("firstNameAndLastName", values.nameAndSurname.charAt(0).toUpperCase() + values.nameAndSurname.slice(1));
        }
        if (values.lastName) {
            formData.append("lastName", values.lastName);
        }
        if (values.address) {
            formData.append("address", values.address);
        }
        if (values.secondAddress) {
            formData.append("secondAddress", values.secondAddress);
        }
        if (values.phoneNr) {
            formData.append("phoneNumber", values.phoneNr);
        }
        if (values.email) {
            formData.append("email", values.email);
        }
        if (values.businessNr) {
            formData.append("businessNumber", values.businessNr);
        }
        if (values.bankAccountNr) {
            formData.append("bankAccountNumber", values.bankAccountNr);
        }
        if (values.bankName) {
            formData.append("bankAccount", values.bankName);
        }
        if (city?.label) {
            formData.append("city", city?.label);
        }
        if (country?.label) {
            formData.append("country", country.label);
        }
        if (values.contactPerson) {
            formData.append("contactPerson", values.contactPerson);
        }

        if (clientId) {
            props.clientActions.editClient(clientId, formData).then((response) => {
                setInitialValues({
                    nameAndSurname: response?.data?.data?.firstnameAndLastname,
                    lastName: response?.data?.data?.lastName,
                    phoneNr: response?.data?.data?.phoneNumber,
                    secondPhoneNr: response?.data?.data?.secondPhoneNumber,
                    email: response?.data?.data?.email || '',
                    comment: response?.data?.data?.commentAndDetails || '',
                    businessNr: response?.data?.data?.businessNumber,
                    address: response?.data?.data?.address,
                    secondAddress: response?.data?.data?.secondAddress,
                    bankAccountNr: response?.data?.data?.bankAccountNumber,
                    bankName: response?.data?.data?.bankAccount || '',
                    contactPerson: response?.data?.data?.contactPerson || '',
                    file: null
                })
                setCity({
                    label: response.data.data.city
                })
                setCountry({
                    label: response.data.data.country
                })
                history.push('/dashboard/contact')
            }).catch(err => toast.error(err.message))

        } else {
            props.clientActions.createClient(formData, letter_, fromDate, toDate).then((response) => {
                let formDataCreate = new FormData()
                props.client?.clientProjects?.length !== 0 && props.client?.clientProjects?.forEach((project, index) => {
                    formDataCreate.append("clientId", response.data?.data?.id);
                    formDataCreate.append("projectName[]", project?.projectName);
                    formDataCreate.append("measureDate[]", moment(project?.measureDate).format('YYYY-MM-DD'));
                    formDataCreate.append("projectLocation[]", project?.projectLocation);
                    formDataCreate.append("projectComment[]", project?.projectComment || project?.projectComment == 'undefined' ? project?.projectComment : '');
                    if (project.orders?.length !== 0) {
                        project.orders?.forEach((order) => {
                            formDataCreate.append(`orders[${index}][]`, order?.value);
                        })
                    } else {
                        formDataCreate.append(`orders[${index}][]`, '');
                    }
                    if (project.projectFiles?.length !== 0) {
                        project.projectFiles?.forEach((file) => {
                            formDataCreate.append(`projectFiles[${index}][]`, file?.file);
                        })
                    } else {
                        formDataCreate.append(`projectFiles[${index}][]`, '');
                    }
                    if (project?.measureAssignee?.value) {
                        formDataCreate.append("measureAssignee[]", project?.measureAssignee?.value);
                    }
                })
                if (response?.data?.data) {
                    let payload = {
                        moduleId: 1,
                        clientId: response.data?.data?.id
                    }
                    setInitialValues({
                        nameAndSurname: response.data?.data?.firstnameAndLastname,
                        lastName: response.data?.data?.lastName,
                        phoneNr: response.data?.data?.phoneNumber,
                        secondPhoneNr: response.data?.data?.secondPhoneNumber,
                        email: response.data?.data?.email,
                        comment: response.data?.data?.commentAndDetails,
                        businessNr: response.data?.data?.businessNumber,
                        address: response.data?.data?.address,
                        secondAddress: response.data?.data?.secondAddress,
                        bankAccountNr: response.data?.data?.bankAccountNumber,
                        bankName: response.data?.data?.bankAccount,
                        contactPerson: response.data?.data?.contactPerson,
                        file: response.data?.data?.productPicture
                    })
                    setCity({
                        label: response.data?.data?.city
                    })
                    setCountry({
                        label: response.data?.data?.country
                    })
                    apiCaller('POST', `modules/create-client-for-next-module`, payload)
                    props.clientActions.createProjects(formDataCreate).then((res) => {
                        if (res?.data?.unique?.name) {
                            toast.error(`Projekti ${res?.data?.unique?.name} ekziston, provoni perseri!`)
                            return
                        }
                        history.push('/dashboard/contact')
                    })
                }
            }).catch(err => console.log(err.message, JSON.stringify(err))).then(() => {
                toast.success('Klienti u krijua me sukses');
            })
        }
        ;
    }

    function getTransferredDataFromModules(clientId) {
        setSearchedClient('')
        history.push(`/dashboard/contact/create/${clientId}`)
    }

    function createClientForm() {
        return (
            <div className="create-client">
                <div
                    className="create-client-row">
                    <p className="w-25 d-flex small-text create-new-text">
                        <span className={'createClientText'}><TranslatedText id="client.createClient"
                                                                               defaultMessage="Krijo klient"/></span>

                        <Tooltip placement="right" overlay={<span><TranslatedText id="app.clearForm"
                                                                                    defaultMessage="Pastro Formen"/></span>}>
                            <img className="create-client-icon" onClick={resetFields} src={clearFormSvg}
                                 alt="create client"/>
                        </Tooltip>
                    </p>
                    <div className={'searchContainer'}>
                        <div className="w-100 position-relative d-flex align-items-end">
                            <div className="searchIcon">
                                <FontAwesome
                                    name="search"
                                />
                            </div>
                            <Input
                                name="nameSearch"
                                value={searchedClient || ''}
                                type="text"
                                inputWrapperClass="col-12 w-100 nameSearch m-0"
                                onChange={(name, value) => setSearchedClient(value)}
                                inputTextType={EInputTextType.Material}
                                placeholder={"Kerko Kontaktin"}
                            />
                            {searchedClient && <div className="suggestedClients-container-measures">
                                {filteredClients?.length === 0 ?
                                    <p className={'m-0 w-100 text-center'}>{language.noData}</p>
                                    :
                                    filteredClients?.map((client) => {
                                        return (
                                            <div className="suggestedClients" key={client.id}
                                                 onClick={() => getTransferredDataFromModules(client.id)}>
                                                <p className="m-0">{client.firstNameAndLastName}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>}
                        </div>
                    </div>
                </div>
                <FormikFormWrapper initialValues={initialValues} validationScheme={validationScheme()}
                                   onSubmit={saveClient}>
                    <div className="w-100 mx-0 row d-flex justify-content-between">
                        <div className="w-100 left-side d-flex flex-column">
                            <div className="row">
                                <MikaTextInput className={'col-6 mobileWidth'} name={'nameAndSurname'} bordered
                                               labelKey={translatedText({
                                                   id: "name",
                                                   defaultMessage: "Emri *"
                                               })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'lastName'} bordered
                                               labelKey={translatedText({
                                                   id: "lastName",
                                                   defaultMessage: "Mbiemri *"
                                               })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'phoneNr'} bordered
                                               labelKey={translatedText({
                                                   id: "phoneNr",
                                                   defaultMessage: "Telefoni *"
                                               })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'secondPhoneNr'} bordered
                                               labelKey={translatedText({
                                                   id: "secondPhoneNr",
                                                   defaultMessage: "Telefoni i dyte"
                                               })}/>
                                <div className={'mikaTextInputWrapper d-flex flex-column col-6 mobileWidth'}>
                                    <p className={'inputLabelOutside'}><TranslatedText id="client.country"
                                                                                         defaultMessage="Shteti *"/>
                                    </p>
                                    <MikaSelect
                                        options={countries}
                                        value={country || ''}
                                        setEntireOptionFunction={setCountry}
                                        placeholder="Shteti"
                                        showRequiredMessage={showRequiredMessage}
                                        requiredMessage={translatedText({
                                                     id: "fillField",
                                                     defaultMessage: "Ju lutem mbushni fushen!"
                                                 })}
                                        selectWrapperClassName=" w-100 heightFitContent mb-bottom"/>
                                </div>
                                <div className={'mikaTextInputWrapper d-flex flex-column col-6 mobileWidth'}>
                                    <p className={'inputLabelOutside'}><TranslatedText id="client.city"
                                                                                         defaultMessage="Qyteti *"/>
                                    </p>
                                    <MikaSelect
                                        options={cities}
                                        value={city || ''}
                                        setEntireOptionFunction={setCity}
                                        placeholder="Qyteti"
                                        showRequiredMessage={showRequiredMessage}
                                        requiredMessage={translatedText({
                                                     id: "fillField",
                                                     defaultMessage: "Ju lutem mbushni fushen!"
                                                 })}
                                        selectWrapperClassName=" w-100 heightFitContent m-b mb-bottom"/>
                                </div>
                                <MikaNumberInput className={'col-6 mobileWidth'} name={'businessNr'} bordered
                                                 labelKey={translatedText({
                                                     id: "client.businessNr",
                                                     defaultMessage: "Numri i biznesit / Id"
                                                 })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'address'} bordered
                                               labelKey={translatedText({
                                                   id: "client.address",
                                                   defaultMessage: "Adresa"
                                               })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'secondAddress'} bordered
                                               labelKey={translatedText({
                                                   id: "client.secondAddress",
                                                   defaultMessage: "Adresa e dyte"
                                               })}/>

                                <MikaTextInput className={'col-6 mobileWidth'} name={'email'} bordered
                                               labelKey={translatedText({
                                                   id: "client.email",
                                                   defaultMessage: "Email"
                                               })}/>
                                <MikaTextInput className={'col-6 mobileWidth'} name={'bankAccountNr'} bordered
                                               labelKey={translatedText({
                                                   id: "client.bankAccountNr",
                                                   defaultMessage: "Numri Bankare"
                                               })}/>
                                <MikaTextInput className={'col-6 pr-12 mobileWidth'} name={'bankName'} bordered
                                               labelKey={translatedText({
                                                   id: "client.bankName",
                                                   defaultMessage: "Emri i llogarise bankare"
                                               })}/>
                                <MikaTextInput className={'col-6 pl-12 mobileWidth'} name={'contactPerson'} bordered
                                               labelKey={translatedText({
                                                   id: "client.contactPerson",
                                                   defaultMessage: "Personi kontaktues"
                                               })}/>
                            </div>
                            <div className={'projectCreationButtonContainer'}>
                                {isProjectModalOpened &&
                                <CreateProjectModal clientId={clientId} getModuleData={getModuleData}
                                                    closeModalFunction={setIsProjectModalOpened}/>}
                            </div>
                            {createAt && <div className="created-at-date">
                                <p><TranslatedText id="client.editContact"
                                                     defaultMessage="Krijuar me date:"/> {formatDate(createAt)}</p>
                            </div>}
                            <div
                                className={`row justify-content-end mobileAlign ${isMobile ? 'hugePaddingBottom' : ''}`}>
                                <div className="w-50 text-right mobileWidth">
                                    <Button
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        className="w-100 mt-0"
                                        onClick={() => {
                                            setIsProjectModalOpened(true)
                                        }}
                                        buttonType={EButtonType.GreyButton}>
                                        <TranslatedText id={'projects'} defaultMessage={'Projektet'} />
                                    </Button>
                                </div>
                                {((!props.permissions.observer) || props.permissions.fullPrivileges) && <div className="w-50 text-right mobileWidth">
                                    <Button
                                        submit={true}
                                        isLoading={props?.buttonLoader}
                                        isDisabled={props?.buttonLoader}
                                        className="m-auto w-100"
                                        buttonType={EButtonType.PurpleButton}>
                                        {clientId ?
                                            <TranslatedText id="client.editContact"
                                                            defaultMessage="Ndrysho kontaktin"/>
                                            :
                                            <TranslatedText id="client.saveContact" defaultMessage="Ruaj kontaktin"/>
                                        }
                                    </Button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </FormikFormWrapper>
            </div>
        )
    }

    return (
        <div className="wrapper">
            {props.loading ? <Spinner/> :
                createClientForm()
            }
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
        client: state.client,
        user: state.user,
        countries: state.app.countriesFields,
        cities: state.app.citiesFields,

    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clientActions: bindActionCreators(clientActions as any, dispatch),
        generalActions: bindActionCreators(generalActions as any, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateContactForm as any)))