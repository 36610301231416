import * as constants from '../constants/constants';
import { axiosCaller } from "../utils/apiCaller";
import { ACTION, wrap, setLoading }  from './generalActions'
import { toast } from 'react-toastify';
import {GET_WAREHOUSE, GET_WAREHOUSE_INNER_STOCK, GET_WAREHOUSE_LEFT_OVERS} from "../constants/constants";

export const fetchTransportAssigneesOptions = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `permission-users?module=transport`).then(res => {
            dispatch({ "type": constants.FETCH_TRANSPORT_ASSIGNEES_OPTIONS, "payload": res.data });
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const fetchInstallationAssigneesOptions = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `permission-users?module=installation`).then(res => {
            dispatch({ "type": constants.FETCH_INSTALLATION_ASSIGNEES_OPTIONS, "payload": res.data });
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const fetchWarehouseClientInformation = (clientId: number) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `warehouse-records/${clientId}/clients`).then(res => {
            dispatch({ "type": constants.FETCH_WAREHOUSE_CLIENT_INFORMATION, "payload": res.data });
            return res.data
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const createWarehouseRecords = (payload) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `warehouse-records`, payload).then(()=>{
            toast.success('Rekordet u krijuan me sukses')
            return
        })
    } catch(err) {
        toast.error('Diqka shkoi keq!')
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const editWarehouseRecords = (payload, warehouseRecordId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `warehouse-records/${warehouseRecordId}`, payload).then(()=>{
            toast.success('Te dhenat u edituan me sukses!')
            return
        })
    } catch(err) {
        toast.error('Diqka shkoi keq!')
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const getWarehouseProducts = (payload, clientId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `warehouse/show`, payload).then(res => {
            dispatch({ "type": constants.GET_WAREHOUSE_PRODUCTS, "payload": res.data.data });
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const getWarehouseRecordExtraInformation = (projectId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `warehouse-records/${projectId}/project`).then(res => {
            console.log(res.data)
            dispatch({ "type": constants.GET_WAREHOUSE_INFORMATION, "payload": res.data.data });
            return
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

// export const getWarehouseRecord = (warehouseRecordId) => wrap(async(dispatch:any) => {
//     try {
//         return await axiosCaller('GET', `warehouse-records/${warehouseRecordId}`).then(res => {
//             dispatch({ "type": constants.GET_WAREHOUSE, "payload": res.data.data });
//             return
//         })
//     } catch(err) {
//     }
// }, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const setWarehouseRecord = (payload) => wrap(async(dispatch:any) => {
        dispatch({ "type": constants.GET_WAREHOUSE_RECORD, "payload": payload });
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const deleteWarehouseRecord = (warehouseRecordId) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('DELETE', `warehouse-records/${warehouseRecordId}`).then(res => {
            toast.info('Deponimi u fshi me sukses!')
            return
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const getWarehouseLeftOvers = (payload) => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('POST', `warehouse-records/quantity`, payload).then(res => {
            dispatch({ "type": constants.GET_WAREHOUSE_LEFT_OVERS, "payload": res.data });
            return
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')

export const getInnerStockProducts = () => wrap(async(dispatch:any) => {
    try {
        return await axiosCaller('GET', `warehouse-records/inner/stock`).then(res => {
            dispatch({ "type": constants.GET_WAREHOUSE_INNER_STOCK, "payload": res?.data?.data });
            return
        })
    } catch(err) {
    }
}, 'FETCH_TRANSPORT_ASSIGNEES_OPTIONS')